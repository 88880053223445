<template>
  <div class="main-content">
    <!-- <div class="col-12 col-lg-10 col-xl-8"> -->
    <!-- Header -->
    <div class="header">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <!-- Pretitle -->
              <h6 class="header-pretitle">
                {{ $t("admin.title") }}
              </h6>

              <!-- Title -->
              <h1 class="header-title" v-if="activeTab == 0">
                {{ $t("admin.all_companies") }}
              </h1>
              <h1 class="header-title" v-if="activeTab == 1">
                {{ $t("menu.all_locations") }}
              </h1>
              <h1 class="header-title" v-if="activeTab == 2">
                {{ $t("admin.all_users") }}
              </h1>
              <h1 class="header-title" v-if="activeTab == 3">
                {{ $t("menu.all_doors") }}
              </h1>
              <h1 class="header-title" v-if="activeTab == 4">
                {{ $t("menu.all_keys") }}
              </h1>
              <h1 class="header-title" v-if="activeTab == 5">
                {{ $t("menu.staff") }}
              </h1>
              <h1 class="header-title" v-if="activeTab == 6">
                {{ $t("doors.saltoInstallation") }}
              </h1>
              <h1 class="header-title" v-if="activeTab == 7">
                {{ $t("admin.admin_activity") }}
              </h1>
            </div>
          </div>
          <!-- / .row -->
          <div class="row align-items-center">
            <div class="col">
              <!-- Nav -->
              <b-nav tabs class="nav nav-tabs nav-overflow header-tabs">
                <!-- @click="activeTab = 0" -->
                <b-nav-item :active="activeTab == 0" @click.stop.prevent="activeTabView(0)">
                  {{ $t("admin.all_companies") }}
                </b-nav-item>
                <b-nav-item :active="activeTab == 1" @click.stop.prevent="activeTabView(1)">
                  {{ $t("menu.all_locations") }}
                </b-nav-item>
                <b-nav-item :active="activeTab == 2" @click.stop.prevent="activeTabView(2)">
                  {{ $t("admin.all_users") }}
                </b-nav-item>
                <b-nav-item :active="activeTab == 3" @click.stop.prevent="activeTabView(3)">
                  {{ $t("menu.all_doors") }}
                </b-nav-item>
                <b-nav-item :active="activeTab == 4" @click.stop.prevent="activeTabView(4)">
                  {{ $t("menu.all_keys") }}
                </b-nav-item>
                <b-nav-item :active="activeTab == 5" @click.stop.prevent="activeTabView(5)">
                  {{ $t("menu.staff") }}
                </b-nav-item>
                <b-nav-item :active="activeTab == 6" @click.stop.prevent="activeTabView(6)">
                  {{ $t("doors.saltoInstallation") }}
                </b-nav-item>
                <b-nav-item :active="activeTab == 7" @click.stop.prevent="activeTabView(7)">
                  {{ $t("admin.admin_activity") }}
                </b-nav-item>
              </b-nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="card" v-show="activeTab == 0">
        <div class="card-header">
            <h4 class="card-header-title"></h4>

          <div class="col-auto">
            <form>
              <Select2
                v-model="perPageCompany"
                :options="perPageOptions"
                id="mySelect1"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
                @change="initCompany"
              />
            </form>
          </div>
          <!-- <div class="col-auto">
            !-- Dropdown --
            <b-button
              class="btn-sm btn-white"
              @click="showCompanyFilters = !showCompanyFilters"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div> -->
          <div class="col-auto">
              <!-- Select -->
              <!-- <form class="mr-3">
                <b-dropdown
                  variant="outline"
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  right
                  no-caret
                >
                  <template v-slot:button-content>{{
                    $t("menu.sort_order")
                  }}</template>
                  <b-dropdown-item @click="sortOrderCompany = 'asc'"
                    >Asc</b-dropdown-item
                  >
                  <b-dropdown-item @click="sortOrderCompany = 'desc'"
                    >Desc</b-dropdown-item
                  >
                </b-dropdown>
              </form> -->
              <form>
              <Select2
                v-model="sortOrderCompany"
                :options="sortOrderOptions"
                id="mySelect2"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>
          <div class="col-auto">
            <b-button
              variant="primary"
              size="sm"
              @click.stop.prevent="createCompany"
              >{{ $t("companies.create") }}</b-button
            >
          </div>
        </div>
        <div class="card-header">
          <!-- Form -->
          <form>
            <div class="input-group input-group-flush input-group-merge">
              <!-- Input -->

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterCompany"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              <!-- Prepend -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="card-body">
          <b-pagination
                v-model="currentPageCompany"
                :total-rows="companiesList.length"
                :per-page="perPageCompany"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
          <zs-list-transition :showLoader="showCompanyLoader">
            <template v-slot:content>
              <user-company-item-admin
                v-for="(company, index) in paginatedCompanyList"
                :company="company"
                v-bind:key="index"
              />
            </template>
            <template v-slot:empty>
              <empty-list :title="$t('companies.no_companies')" />
            </template>
          </zs-list-transition>

          <b-pagination
                v-model="currentPageCompany"
                :total-rows="companiesList.length"
                :per-page="perPageCompany"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
        </div>

        <div id="modalsCompany">
            <zs-company-admin-modal/>
            <zs-company-delete-admin-modal/>
            <!-- <zs-image-upload-modal @imageUploaded="updateCompanyImage" /> -->
        </div>

      </div>
      <div class="card" v-show="activeTab == 1">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title"></h4>
          <div class="col-auto">
            <form>
              <Select2
                v-model="perPageLocation"
                :options="perPageOptions"
                id="mySelect3"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
                @change="initLocation"
              />
            </form>
          </div>
          <div class="col-auto">
              <!-- <b-form-select
                    id="locationCompany"
                    :options="companiesOptions"
                    :placeholder="$t('locations.company_select')"
                    v-model="companySelected"
                ></b-form-select> -->
                <!-- @change="initLocation" @change="companySelected" -->
                <form>
                <Select2
                  id="locationCompany"
                  :options="companiesOptions"
                  :disabled="companiesOptions.length == 0"
                  class= "select-dropdown"
                  v-model="companySelected"
                  :settings="{
                      placeholder: $t('locations.company_select'),
                      allowClear: true,
                      minimumResultsForSearch: 1,
                      containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                      dropdownCssClass:
                      'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                  }"
                />
                </form>
          </div>
          <!-- <div class="col-auto">
            !-- Dropdown --
            <b-button
              class="btn-sm btn-white"
              @click="showLocationFilters = !showLocationFilters"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div> -->
          <div class="col-auto">
              <!-- Select -->
              <!-- <form class="mr-3">
                <b-dropdown
                  variant="outline"
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  right
                  no-caret
                >
                  <template v-slot:button-content>{{
                    $t("menu.sort_order")
                  }}</template>
                  <b-dropdown-item @click="sortOrderLocation = 'asc'"
                    >Asc</b-dropdown-item
                  >
                  <b-dropdown-item @click="sortOrderLocation = 'desc'"
                    >Desc</b-dropdown-item
                  >
                </b-dropdown>
              </form> -->
              <form>
              <Select2
                v-model="sortOrderLocation"
                :options="sortOrderOptions"
                id="mySelect4"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>
          <!-- Button -->
         <!--  v-if="getAllAdminCompanies.length" -->
          <b-button
            variant="primary"
            size="sm"
            @click.stop.prevent="createLocation"
            >{{ $t("locations.create") }}</b-button
          >
        </div>
        <div class="card-header">
          <!-- Form -->
          <form>
            <div class="input-group input-group-flush input-group-merge">
              <!-- Input -->

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterLocation"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              <!-- Prepend -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
          <b-pagination
                v-model="currentPageLocation"
                :total-rows="locationsList.length"
                :per-page="perPageLocation"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
          <zs-list-transition :showLoader="showLocationLoader">
            <template v-slot:content>
              <location-list-item-admin
                :location="location"
                v-for="location in paginatedLocationList"
                v-bind:key="location.Location.Id"
              />
            </template>
            <template v-slot:empty>
              <empty-list
                :title="
                  getAllAdminCompanies.length
                    ? $t('locations.no_locations')
                    : $t('companies.no_companies')
                "
              >
                <template v-slot:button v-if="!getAllAdminCompanies.length"
                  ><b-button variant="primary" @click.stop.prevent="createCompany">
                    {{ $t("companies.create") }}
                  </b-button></template
                >
              </empty-list>
            </template>
          </zs-list-transition>
          <b-pagination
                v-model="currentPageLocation"
                :total-rows="locationsList.length"
                :per-page="perPageLocation"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
        </div>

        <zs-location-admin-modal />
        <zs-location-delete-admin-modal/>
        <!-- <zs-image-upload-modal @imageUploaded="updateLocationImage" /> -->
      </div>
      <div class="card" v-show="activeTab == 2">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title"></h4>
          <div class="col-auto">
            <form>
              <Select2
                v-model="perPageUser"
                :options="perPageOptions"
                id="mySelect5"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
                @change="initUser"
              />
            </form>
          </div>
          <div class="col-auto">
            <!-- Dropdown -->
           <!--  @click="showUserFilters = !showUserFilters"
              @change="filterUserStatus" -->
            <b-button
              class="btn-sm btn-white"
              @click="filterUserStatus(showUserFilters = !showUserFilters)"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div>
           <div class="col-auto">
              <!-- Select -->
              <!-- <form class="mr-3">
                <b-dropdown
                  variant="outline"
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  right
                  no-caret
                >
                  <template v-slot:button-content>{{
                    $t("menu.sort_order")
                  }}</template>
                  <b-dropdown-item @click="sortOrderUser = 'asc'"
                    >Asc</b-dropdown-item
                  >
                  <b-dropdown-item @click="sortOrderUser = 'desc'"
                    >Desc</b-dropdown-item
                  >
                </b-dropdown>
              </form> -->
              <form>
              <Select2
                v-model="sortOrderUser"
                :options="sortOrderOptions"
                id="mySelect6"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>
          <div class="col-auto">
           <!--  v-if="$can('location-staff-manage', getActiveLocation)" -->
          <!-- <b-button
            variant="primary"
            size="sm"
            class="mr-2"
            @click.stop.prevent="createInvite"
          >
            {{ $t("staff.invite") }}
          </b-button> -->
          <b-button variant="primary" size="sm" @click.stop.prevent="inviteMember">{{
            $t("keys.send_invite")
          }}</b-button>
          </div>
        </div>
        <div class="card-body b-border" v-if="showUserFilters">
          <div class="list-group list-group-flush mt-n4">
            <div class="list-group-item" style="overflow: hidden;">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.filter") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedUsersFilter"
                            @change="setUsersFilter"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="usersFilter in usersFilters"
                              v-bind:key="usersFilter.key"
                              :value="usersFilter"
                              >{{ $t(usersFilter.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4" v-if="isMobile || (this.width<700)">
                          <Select2
                            v-model="selectedUsersFilterValue"
                            id="mySelect7"
                            class="select-dropdown"
                            :options="filterUsersValuesList"
                            :disabled="!usersFilterBoxDisable"
                            v-if="usersFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="applyUserFilter"
                          />
                         <!--   @change="applyKeyFilter" -->
                        </div>
                        <div class="col-4" v-else>
                          <Select2
                            v-model="selectedUsersFilterValue"
                            id="mySelect7"
                            class="select-dropdown"
                            :options="filterUsersValuesList"
                            :disabled="!usersFilterBoxDisable"
                            v-if="usersFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="applyUserFilter"
                          />
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
          </div>
        </div>
        <div class="card-header">
          <!-- Form -->
          <form>
            <div class="input-group input-group-flush input-group-merge">
              <!-- Input -->

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterUser"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              <!-- Prepend -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-header">
          <div class="col-auto">
              <b-pagination
                v-model="currentPageUser"
                :total-rows="locationUsersList.length"
                :per-page="perPageUser"
                class="mt-3"
                aria-controls="my-table"
              ></b-pagination>
          </div>
         <!--  class="mt-n7"
            style="margin-left: 316px;"
          :disabled="userList.length == 0"
        || this.$session.get('deleteFlag') -->
          <div class="col-auto">
            <b-button
            variant="primary"
            size="sm"
            @click.stop.prevent="removeLocationUser"
            :disabled="userList.length == 0"
            v-if="checkBoxFlag">
            {{
              $t("buttons.delete_selectedUser")
            }}
          </b-button>
          </div>
        </div>
        <div class="card-body">
          <!-- :items="locationUsersList" -->

          <zs-list-transition :showLoader="showUsersLoader"></zs-list-transition>
          <b-table
                            responsive
                            hover
                            :items="paginatedUserInstallationList"
                            v-if="locationUsersList.length && !showUsersLoader && !isMobile"
                            :fields="fields"
                        >
                            <template #cell(User)="data">
                              <b-form-checkbox
                                v-model="userList"
                                :key="data.item.User.Id"
                                :value="data.item"
                                @change="disconnectUser"
                                :disabled='!checkBoxFlag'
                              >
                                <div class="avatar avatar-xs align-middle mr-2 ml-3">
                                  <!-- v-if="data.item.Location.CompanyId != null" -->
                                    <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Location.CompanyId,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                    >
                                    <img
                                        class="avatar-img rounded-circle"
                                        :src="data.item.User.SmallImageUrl"
                                        v-if="data.item.User.SmallImageUrl"
                                    />
                                    <div
                                        v-else
                                        class="
                                            avatar-title
                                            font-size-lg
                                            bg-primary-soft
                                            rounded-circle
                                            text-primary
                                        "
                                    >
                                        <img :src="'/images/person_MyZesec_64dp.png'"  style="width: 32px; height: 32px;"/>
                                    </div></router-link>
                                </div>
                               <!--  v-if="data.item.Location.CompanyId != null"      -->
                                <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Location.CompanyId,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                    > {{userFName(data.item.User.FirstName)+" "+userLName(data.item.User.LastName)}}
                                    <p style="white-space: pre;" class="ml-5 text-muted">{{locationName(data.item.Location.Id)}}</p></router-link
                                >
                              </b-form-checkbox>
                            </template>
                            <template #cell(actions)="data" v-if='!checkBoxFlag'>
                                <b-dropdown
                                    variant="outline"
                                    toggle-class="dropdown-ellipses dropdown-toggle"
                                    no-caret
                                    right
                                    toggle-tag="a"
                                    size="sm"
                                >
                                    <template v-slot:button-content>
                                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                    </template>
                                    <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="deleteUser(data.item)"
                                        >{{ $t("buttons.delete") }}</b-dropdown-item
                                    >
                                    <!-- <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="removeLocationUser(data.item)"
                                        >{{ $t("buttons.remove_from_location") }}</b-dropdown-item
                                    > -->
                                </b-dropdown>
                            </template>
                        </b-table>
                        <b-table
                            responsive
                            hover
                            :items="paginatedUserInstallationList"
                            v-else-if="locationUsersList.length && !showUsersLoader && isMobile"
                            :fields="fields"
                        >
                            <template #cell(User)="data">
                              <b-form-checkbox
                                v-model="userList"
                                :key="data.item.User.Id"
                                :value="data.item"
                                @change="disconnectUser"
                                :disabled='!checkBoxFlag'
                              >
                                <div class="avatar avatar-xs align-middle mr-2 ml-3">
                                    <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Location.CompanyId,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                    >
                                    <img
                                        class="avatar-img rounded-circle"
                                        :src="data.item.User.SmallImageUrl"
                                        v-if="data.item.User.SmallImageUrl"
                                    />
                                    <div
                                        v-else
                                        class="
                                            avatar-title
                                            font-size-lg
                                            bg-primary-soft
                                            rounded-circle
                                            text-primary
                                        "
                                    >
                                        <img :src="'/images/person_MyZesec_64dp.png'" style="width: 32px; height: 32px;"/>
                                    </div></router-link>
                                </div>
                                <router-link
                                    class="item-name text-reset"
                                    :to="{
                                        name: 'profile',
                                        params: {
                                            locationId: data.item.Location.Id,
                                            companyId: data.item.Location.CompanyId,
                                            userId: data.item.User.Id,
                                        },
                                    }"
                                    ><p style="white-space: pre;">{{userFName(data.item.User.FirstName) +"\n"+ userLName(data.item.User.LastName)}}</p>
                                    <p style="white-space: pre;" class="text-muted">{{locationName(data.item.Location.Id)}}</p></router-link
                                >
                              </b-form-checkbox>
                            </template>

                            <!-- data.item.User.Type != 'Light' && -->
                            <template #cell(actions)="data" v-if='!checkBoxFlag'>
                                <b-dropdown
                                    variant="outline"
                                    toggle-class="dropdown-ellipses dropdown-toggle"
                                    no-caret
                                    right
                                    toggle-tag="a"
                                    size="sm"
                                >
                                    <template v-slot:button-content>
                                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                                    </template>
                                   <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="deleteUser(data.item)"
                                        >{{ $t("buttons.delete") }}</b-dropdown-item
                                    >
                                    <!-- <b-dropdown-item
                                        class="text-left text-truncate"
                                        @click="removeLocationUser(data.item)"
                                        >{{ $t("buttons.remove_from_location") }}</b-dropdown-item
                                    >  -->
                                </b-dropdown>
                            </template>
                        </b-table>
                        <b-pagination
                v-model="currentPageUser"
                :total-rows="locationUsersList.length"
                :per-page="perPageUser"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
        </div>
        <zs-user-access-delete-modal/>
      </div>
      <div class="card" v-show="activeTab == 3">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title"></h4>
          <div class="col-auto">
            <form>
               <Select2
                  v-model="perPageDevice"
                  :options="perPageOptions"
                  id="mySelect8"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"
                  @change="initDevice"
               />
            </form>
          </div>
          <div class="col-auto">
            <!-- Dropdown -->
            <b-button
              class="btn-sm btn-white"
              @click="filterDeviceStatus(showDoorFilters = !showDoorFilters)"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div>
          <div class="col-auto">
              <!-- Select -->
              <!-- <form class="mr-3">
                <b-dropdown
                  variant="outline"
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  right
                  no-caret
                >
                  <template v-slot:button-content>{{
                    $t("menu.sort_order")
                  }}</template>
                  <b-dropdown-item @click="sortOrderDoor = 'asc'"
                    >Asc</b-dropdown-item
                  >
                  <b-dropdown-item @click="sortOrderDoor = 'desc'"
                    >Desc</b-dropdown-item
                  >
                </b-dropdown>
              </form> -->
              <form>
              <Select2
                v-model="sortOrderDoor"
                :options="sortOrderOptions"
                id="mySelect9"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>
            <!--  v-if="paginatedList.length"  -->
            <b-button
            variant="primary"
            size="sm"
            @click.stop.prevent="createDevice"
            >{{ $t("doors.create") }}</b-button
          >
          <div class="col-auto">
            <form>
                <b-button-group class="form-control-height">
                  <button
                    class="btn btn-white"
                    @click.stop.prevent="pageView('list')"
                    v-bind:class="{ active: viewMode == 'list' }"
                  >
                    <span class="fe fe-list"></span>
                  </button>
                  <button
                    class="btn btn-white"
                    @click.stop.prevent="pageView('card')"
                    v-bind:class="{ active: viewMode == 'card' }"
                  >
                    <span class="fe fe-grid"></span>
                  </button>
                </b-button-group>
              </form>
          </div>
        </div>
        <div class="card-body b-border" v-if="showDoorFilters">
          <div class="list-group list-group-flush mt-n4">
            <div class="list-group-item" style="overflow: hidden;">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.filter") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedDeviceFilter"
                            @change="setDeviceFilter"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="deviceFilter in deviceFilters"
                              v-bind:key="deviceFilter.key"
                              :value="deviceFilter"
                              >{{ $t(deviceFilter.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4" v-if="isMobile || (this.width<700)">
                         <!--  v-bind:class="{ active: this.selectedDeviceFilter.key !== 'all'}"
                         :disabled="this.selectedDeviceFilter.key == 'all'" -->
                          <Select2
                            id="deviceFilterBox"
                            v-model="selectedDeviceFilterValue"
                            class="select-dropdown"
                            :options="filterDeviceValuesList"
                            :disabled="!deviceFilterBoxDisable"
                            v-if="deviceFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="applyDeviceFilter"
                          />
                         <!--   @change="applyDeviceFilter" -->
                        </div>
                        <div class="col-4" v-else>
                          <Select2
                            v-model="selectedDeviceFilterValue"
                            id="deviceFilterBox"
                            class="select-dropdown"
                            :options="filterDeviceValuesList"
                            :disabled="!deviceFilterBoxDisable"
                            v-if="deviceFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="applyDeviceFilter"
                          />
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
          </div>
        </div>
        <div class="card-header">
          <!-- Form -->
          <form>
            <div class="input-group input-group-flush input-group-merge">
              <!-- Input -->

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterDoor"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              <!-- Prepend -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
          <b-pagination
                v-model="currentPageDevice"
                :total-rows="devicesList.length"
                :per-page="perPageDevice"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
          <div
                class="tab-pane show active"
                v-show="viewMode == 'list'"
                transition="fade"
              >
                <div class="row list">
                  <zs-list-transition
                    :showLoader="showDeviceLoader"
                    class="w-100"
                  >
                    <template v-slot:content>
                      <door-card-item-admin
                        v-for="device in paginatedList"
                        v-bind:key="device.Device.Id"
                        :device="device"
                      />
                    </template>
                    <template v-slot:empty>
                      <empty-list :title="$t('doors.no_doors')" />
                    </template>
                  </zs-list-transition>
                </div>
              </div>

              <div
                class="tab-pane show active"
                v-show="viewMode == 'card'"
                transition="fade"
              >
              <div
                class="d-flex justify-content-center text-center my-3"
                v-if="!paginatedList.length"
              >
                <b-spinner :label="$t('common.loading')"></b-spinner>
              </div>
                <div class="row listAlias" v-else-if="devicesList.length">
                  <door-large-card-item-admin
                    v-for="device in paginatedList"
                    v-bind:key="device.Device.Id"
                    :device="device"
                  />
                </div>
                <empty-list v-else :title="$t('doors.no_doors')" />
              </div>
              <b-pagination
                v-model="currentPageDevice"
                :total-rows="devicesList.length"
                :per-page="perPageDevice"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
        </div>


            <zs-device-admin-modal/>
             <!-- :locationId="this.device.Device.LocationId" -->
            <zs-device-delete-admin-modal/>
            <zs-device-qr-modal />
           <!--  <zs-image-upload-modal @imageUploaded="updateDoorImage" /> -->
      </div>
      <div class="card" v-show="activeTab == 4">
        <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title"></h4>
              <div class="col-auto">
            <form>
              <Select2
                v-model="perPageKey"
                :options="perPageOptions"
                id="mySelect10"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
                @change="initKey"
              />
            </form>
          </div>
          <div class="col-auto">
            <!-- Dropdown -->
            <b-button
              class="btn-sm btn-white"
              @click="filterKeyStatus(showKeyFilters = !showKeyFilters)"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div>
              <div class="col-auto">
              <!-- Select -->
              <!-- <form class="mr-3">
                <b-dropdown
                  variant="outline"
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  right
                  no-caret
                >
                  <template v-slot:button-content>{{
                    $t("menu.sort_order")
                  }}</template>
                  <b-dropdown-item @click="sortOrderKey = 'asc'"
                    >Asc</b-dropdown-item
                  >
                  <b-dropdown-item @click="sortOrderKey = 'desc'"
                    >Desc</b-dropdown-item
                  >
                </b-dropdown>
              </form> -->
              <form>
              <Select2
                v-model="sortOrderKey"
                :options="sortOrderOptions"
                id="mySelect11"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>
             <b-button
            variant="primary"
            size="sm"
            @click.stop.prevent="createKey"
            >{{ $t("keys.create") }}</b-button
          >
            <div class="col-auto">
             <!--  v-if="$can('location-staff-manage', getActiveLocation)"
          <b-button
            variant="primary"
            size="sm"
            class="mr-2"
            @click.stop.prevent="createInvite"
          >
            {{ $t("staff.invite") }}
          </b-button>  -->
          <b-button variant="primary" size="sm" @click.stop.prevent="inviteMember">{{
            $t("keys.send_invite")
          }}</b-button>
            </div>
        </div>
        <div class="card-body b-border" v-if="showKeyFilters">
          <div class="list-group list-group-flush mt-n4">
            <div class="list-group-item" style="overflow: hidden;">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.filter") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedKeyFilter"
                            @change="setKeyFilter"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="keyFilter in keyFilters"
                              v-bind:key="keyFilter.key"
                              :value="keyFilter"
                              >{{ $t(keyFilter.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4" v-if="isMobile || (this.width<700)">
                          <Select2
                            v-model="selectedKeyFilterValue"
                            id="mySelect12"
                            class="select-dropdown"
                            :options="filterKeyValuesList"
                            :disabled="!keyFilterBoxDisable"
                            v-if="keyFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="applyKeyFilter"
                          />
                         <!--   @change="applyKeyFilter" -->
                        </div>
                        <div class="col-4" v-else>
                          <Select2
                            v-model="selectedKeyFilterValue"
                            id="mySelect12"
                            class="select-dropdown"
                            :options="filterKeyValuesList"
                            :disabled="!keyFilterBoxDisable"
                            v-if="keyFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="applyKeyFilter"
                          />
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
          </div>
        </div>
            <div class="card-header">
              <!-- Form -->
              <form>
                <div class="input-group input-group-flush input-group-merge">
                  <!-- Input -->

                  <b-form-input
                    :placeholder="$t('common.search')"
                    v-model="filterKey"
                    class="form-control form-control-prepended search"
                    type="search"
                  ></b-form-input>

                  <!-- Prepend -->
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <span class="fe fe-search"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body">
              <b-pagination
                v-model="currentPageKey"
                :total-rows="keysList.length"
                :per-page="perPageKey"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
              <zs-list-transition :showLoader="showkeyLoader">
                <template v-slot:content>
                  <key-list-item-admin
                    v-for="(doorKey, index) in paginatedKeyList"
                    v-bind:key="doorKey.Key.Id + '-' + index"
                    :doorKey="doorKey"
                    :showDeleting="false"
                    class="flex-column align-items-start"
                  />
                </template>
                <template v-slot:empty>
                  <empty-list :title="$t('keys.no_keys')" />
                </template>
              </zs-list-transition>
               <b-pagination
                v-model="currentPageKey"
                :total-rows="keysList.length"
                :per-page="perPageKey"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
            </div>

        <zs-key-admin-modal/>
        <zs-add-door-modal/>
        <zs-disconnect-door-modal/>
        <zs-sharekey-admin-modal/>
        <zs-key-delete-admin-modal/>
      </div>
      <div class="card" v-show="activeTab == 5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title"></h4>
          <div class="col-auto">
            <form>
              <Select2
                v-model="perPageStaff"
                :options="perPageOptions"
                id="mySelect13"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
                @change="initStaff"
              />
            </form>
          </div>
          <div class="col-auto">
                <form>
                <Select2
                  id="staffCompany"
                  :options="companiesOptionsStaff"
                  :disabled="companiesOptionsStaff.length == 0"
                  class= "select-dropdown"
                  v-model="companySelectedStaff"
                  :settings="{
                      placeholder: $t('locations.company_select'),
                      allowClear: true,
                      minimumResultsForSearch: 1,
                      containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                      dropdownCssClass:
                      'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                  }"
                />
                </form>
          </div>
          <!-- <div class="col-auto">
            !-- Dropdown --
            <b-button
              class="btn-sm btn-white"
              @click="showStaffFilters = !showStaffFilters"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div> -->
          <div class="col-auto">
              <!-- Select -->
              <!-- <form class="mr-3">
                <b-dropdown
                  variant="outline"
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  right
                  no-caret
                >
                  <template v-slot:button-content>{{
                    $t("menu.sort_order")
                  }}</template>
                  <b-dropdown-item @click="sortOrderStaff = 'asc'"
                    >Asc</b-dropdown-item
                  >
                  <b-dropdown-item @click="sortOrderStaff = 'desc'"
                    >Desc</b-dropdown-item
                  >
                </b-dropdown>
              </form> -->
              <form>
              <Select2
                v-model="sortOrderStaff"
                :options="sortOrderOptions"
                id="mySelect14"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>
          <div class="col-auto">
            <!-- v-if="$can('location-staff-manage', getActiveLocation)" -->
          <b-button
            variant="primary"
            size="sm"
            class="mr-2"
            @click.stop.prevent="createInvite"
          >
            {{ $t("staff.invite") }}
          </b-button>
          <!-- <b-button variant="primary" size="sm" @click="inviteMember">{{
            $t("keys.send_invite")
          }}</b-button> -->
          </div>
        </div>
        <div class="card-header">
          <!-- Form -->
          <form>
            <div class="input-group input-group-flush input-group-merge">
              <!-- Input -->

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterStaff"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              <!-- Prepend -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
          <b-pagination
                v-model="currentPageStaff"
                :total-rows="getCompanyEmployeesFiltered.length"
                :per-page="perPageStaff"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
          <zs-list-transition :showLoader="showStaffLoader">
            <template v-slot:content>
             <!--  :company="getActiveCompany"  -->
              <employee-list-item-admin
                v-for="(employee, index) in paginatedStaffList"
                v-bind:key="index"
                :employee="employee"
              />
            </template>
            <template v-slot:empty>
              <empty-list :title="$t('staff.no_staff')" />
            </template>
          </zs-list-transition>
          <b-pagination
                v-model="currentPageStaff"
                :total-rows="getCompanyEmployeesFiltered.length"
                :per-page="perPageStaff"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
        </div>

        <div id="modalsStaff">
            <zs-delete-company-user-admin-modal />
            <!-- <zs-delete-location-user-modal /> -->
            <zs-delete-location-user-admin-modal />
            <zs-delete-location-multiple-user-admin-modal/>
            <zs-cancel-invite-admin-modal />
            <zs-delete-key-user-admin-modal />
            <zs-change-owner-modal/>
            <zs-change-location-role-admin-modal/>
            <zs-change-company-role-admin-modal/>
           <!--  <zs-location-invite-admin-modal/>    -->
            <zs-company-invite-admin-modal/>
          </div>
      </div>
      <div class="card" v-show="activeTab == 6">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title"></h4>
          <div class="col-auto">
            <form>
              <Select2
                v-model="perPageSalto"
                :options="perPageOptions"
                id="mySelect15"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
                @change="initSaltoInstallation"
              />
            </form>
          </div>
          <div class="col-auto">
            <!-- Dropdown -->
            <b-button
              class="btn-sm btn-white"
              @click="filterSaltoStatus(showSaltoFilters = !showSaltoFilters)"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div>
          <div class="col-auto">
              <!-- Select -->
              <!-- <form class="mr-3">
                <b-dropdown
                  variant="outline"
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  right
                  no-caret
                >
                  <template v-slot:button-content>{{
                    $t("menu.sort_order")
                  }}</template>
                  <b-dropdown-item @click="sortOrderSalto = 'asc'"
                    >Asc</b-dropdown-item
                  >
                  <b-dropdown-item @click="sortOrderSalto = 'desc'"
                    >Desc</b-dropdown-item
                  >
                </b-dropdown>
              </form> -->
              <form>
              <Select2
                v-model="sortOrderSalto"
                :options="sortOrderOptions"
                id="mySelect16"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>
              <div class="col-auto">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                style="width: 90px;"
                @click.stop.prevent="createSaltoInstallation"
              >
                {{ $t("salto.create_saltoInstallation") }}
              </b-button>
          </div>
        </div>
        <div class="card-body b-border" v-if="showSaltoFilters">
          <div class="list-group list-group-flush mt-n4">
            <div class="list-group-item" style="overflow: hidden;">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.filter") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedSaltoFilter"
                            @change="setSaltoFilter"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="saltoFilter in saltoFilters"
                              v-bind:key="saltoFilter.key"
                              :value="saltoFilter"
                              >{{ $t(saltoFilter.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4" v-if="isMobile || (this.width<700)">
                          <Select2
                            v-model="selectedSaltoFilterValue"
                            id="mySelect17"
                            class="select-dropdown"
                            :options="filterSaltoValuesList"
                            :disabled="!saltoFilterBoxDisable"
                            v-if="saltoFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="applySaltoFilter"
                          />
                         <!--   @change="applyKeyFilter" -->
                        </div>
                        <div class="col-4" v-else>
                          <Select2
                            v-model="selectedSaltoFilterValue"
                            id="mySelect17"
                            class="select-dropdown"
                            :options="filterSaltoValuesList"
                            :disabled="!saltoFilterBoxDisable"
                            v-if="saltoFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="applySaltoFilter"
                          />
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
          </div>
        </div>
        <div class="card-header">
          <!-- Form -->
          <form>
            <div class="input-group input-group-flush input-group-merge">
              <!-- Input -->

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterSalto"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              <!-- Prepend -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
          <b-pagination
                v-model="currentPageSalto"
                :total-rows="getSaltoInstallationList.length"
                :per-page="perPageSalto"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
          <zs-list-transition :showLoader="showSaltoLoader">
            <template v-slot:content>
              <salto-list-item
                 v-for="(salto, index) in paginatedSaltoInstallationList"
                 v-bind:key="index"
                 :salto="salto"
              />
            </template>
            <template v-slot:empty>
              <empty-list :title="$t('doors.no_salto')" />
            </template>
          </zs-list-transition>
           <b-pagination
                v-model="currentPageSalto"
                :total-rows="getSaltoInstallationList.length"
                :per-page="perPageSalto"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
        </div>

        <zs-salto-installation-modal/>
        <!-- :saltoInstallationId="this.salto.SaltoInstallationId" -->
        <zs-salto-installation-delete-admin-modal/>
      </div>
      <div class="card" v-show="activeTab == 7">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title"></h4>
          <div class="col-auto">
                      <b-button
                        size="sm"
                        @click="exportActivity"
                        class="btn-white"
                      >
                        <b-icon
                          icon="download"
                          aria-hidden="true"
                          class="mr-2"
                        ></b-icon
                        >{{ $t("buttons.export") }}
                      </b-button>
                    </div>
          <div class="col-auto">
            <form>
              <Select2
                v-model="perAdminPage"
                :options="perPageOptions"
                id="mySelect18"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"
                  @change="initActivity"
              />
            </form>
          </div>
          <div class="col-auto">
            <!-- Dropdown -->
            <b-button
              class="btn-sm btn-white"
              @click="filterStatus(showActivityFilters = !showActivityFilters)"
            >
              <i class="fe fe-sliders mr-1"></i>
              {{ $t("common.filters") }}
            </b-button>
          </div>
          <!-- <div class="col-auto">
              !-- Select --
              <form>
              <Select2
                v-model="sortOrderActivity"
                :options="sortOrderOptions"
                :settings="{
                  minimumResultsForSearch: -1,
                  containerCssClass:
                    'custom-select custom-select-sm form-control-flush',
                  dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                }"
              />
            </form>
            </div>  -->
        </div>
        <div class="card-body b-border">
                  <div class="list-group list-group-flush mt-n4">
                    <div class="list-group-item" style="overflow: hidden;">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.range") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedRange"
                            @change="setRange"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="range in ranges"
                              :value="range"
                              v-bind:key="range.index"
                              >{{ $t(range.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4">
                          <!--  v-if="selectedRange.index == 0" -->
                          <flat-pickr
                            v-model="flatpickrRange"
                            :config="{
                              mode: 'range',
                              locale: $i18n.locale == 'sv'
                                            ? locale
                                            : 'default',
                            }"
                            :maxDate="maxDate"
                            class="form-control form-control-sm borderColorChange"
                            @on-close="initActivityRange"
                            :disabled="selectedRange.index != 0"
                          ></flat-pickr>
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                    <div class="list-group-item" style="overflow: hidden;"  v-if="showActivityFilters">
                      <div class="row">
                        <div class="col">
                          <small>{{ $t("common.filter") }}</small>
                        </div>
                        <div class="col-4 mr-n3">
                          <b-form-select
                            size="sm"
                            :value="selectedFilter"
                            @change="setFilter"
                          >
                            <b-form-select-option
                              class="text-left text-truncate"
                              v-for="filterVal in filters"
                              v-bind:key="filterVal.key"
                              :value="filterVal"
                              >{{ $t(filterVal.name) }}</b-form-select-option
                            >
                          </b-form-select>
                        </div>
                        <div class="col-4" v-if="isMobile || (this.width<700)">
                          <Select2
                            v-model="selectedFilterValue"
                            id="mySelect19"
                            class="select-dropdown"
                            :options="filterValuesList"
                            :disabled="!activityFilterBoxDisable"
                            v-if="activityFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm dropdown-menu-addition',
                            }"
                            @change="applyFilter"
                          />
                        </div>
                        <div class="col-4" v-else>
                          <Select2
                            v-model="selectedFilterValue"
                            id="mySelect19"
                            class="select-dropdown"
                            :options="filterValuesList"
                            :disabled="!activityFilterBoxDisable"
                            v-if="activityFilterBoxDisable"
                            :settings="{
                              containerCssClass:
                                'custom-select custom-select-sm form-control form-control-sm borderColorChange',
                              dropdownCssClass:
                                'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="applyFilter"
                          />
                          <!-- :disabled="filterValuesList.length == 0" -->
                        </div>
                      </div>
                      <!-- / .row -->
                    </div>
                  </div>
                </div>
       <!--  <div class="card-header">
          !-- Form --
          <form>
            <div class="input-group input-group-flush input-group-merge">
              !-- Input --

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filterActivity"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              !-- Prepend --
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div> -->
        <div class="card-body">
          <!--  <zs-list-transition :showLoader="showActivityLoader">
              <template v-slot:content>
                 !-- <zs-activity-timeline
                  v-for="(activity, index) in paginatedActivityList"
                  v-bind:key="index"
                  :activity="activity"
                  :activityLog="paginatedActivityList"
                  /> --
                  <zs-activity-timeline
                    v-for="activity in paginatedAdminActivityList"
                    v-bind:key="activity.Id"
                    :activityLog="paginatedAdminActivityList"
                  />
            </template>
            <template v-slot:empty>
              <empty-list :title="$t('activity.no_activity')" />
            </template>
          </zs-list-transition> -->
         <!--  v-if="!paginatedAdminActivityList.length" -->
         <b-pagination
                v-model="currentAdminPage"
                :total-rows="activityList.length"
                :per-page="perAdminPage"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
              <div
                class="d-flex justify-content-center text-center my-3"
                v-if="activityFlag == true && !paginatedAdminActivityList.length"
              >
                <b-spinner :label="$t('common.loading')"></b-spinner>
              </div>

             <zs-activity-timeline
              v-else-if="paginatedAdminActivityList.length"
              :activityLog="paginatedAdminActivityList"
            />
            <empty-list v-else :title="$t('activity.no_activity')" />
          <b-pagination
                v-model="currentAdminPage"
                :total-rows="activityList.length"
                :per-page="perAdminPage"
                aria-controls="my-table"
                class="pb-2"
        ></b-pagination>
        </div>

      </div>
    </div>
    <!-- <zs-image-upload-modal @imageUploaded="updateCompanyImage" />  -->
    <!-- </div> -->
    <!-- <zs-image-upload-modal @imageUploaded="imageUploaded" /> -->
    <!-- <zs-danalock-modal @connected="danalockConnected = true" /> -->
    <zs-image-upload-modal v-if="activeTab == 0" @imageUploaded="updateCompanyImage" />
    <zs-image-upload-modal v-if="activeTab == 1" @imageUploaded="updateLocationImage" />
    <zs-image-upload-modal v-if="activeTab == 3" @imageUploaded="updateDoorImage" />
  </div>
</template>
<script type="text/javascript" src="main.js"></script>
<script>
import AggregatedController from "@/api/aggregated";
import {
  ACTIVITY_LOG_LOCATION,
  ACTIVITY_LOG_ADMIN,
  activityEventTypes,
  activityAdminTypes,
  activityAllTypes,
  ACTIVITY_TYPE_ROLE_SET,
  ACTIVITY_TYPE_KEY_CREATED,
  ACTIVITY_TYPE_KEY_DELETED,
  ACTIVITY_TYPE_KEY_UPDATED,
  ACTIVITY_TYPE_DEVICE_UPDATED,
  ACTIVITY_TYPE_DEVICE_DELETED,
  ACTIVITY_TYPE_DEVICE_CREATED,
  ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED,
  ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED,
  ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED,
  ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED,
  ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED,
  ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED,
  ACTIVITY_TYPE_LOCATION_CREATED,
  ACTIVITY_TYPE_LOCATION_UPDATED,
  ACTIVITY_TYPE_LOCATION_DELETED,
  ACTIVITY_TYPE_COMPANY_CREATED,
  ACTIVITY_TYPE_COMPANY_UPDATED,
  ACTIVITY_TYPE_COMPANY_DELETED,
  ACTIVITY_TYPE_INVITE_CREATED,
  ACTIVITY_TYPE_ASSIGNED_ROLE_CO,
  ACTIVITY_TYPE_ASSIGNED_ROLE_CM,
  ACTIVITY_TYPE_ASSIGNED_ROLE_LM,
  ACTIVITY_TYPE_ASSIGNED_ROLE_KM,
  ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER,
  ACTIVITY_TYPE_INVITE_ACCEPTED,
  ACTIVITY_TYPE_DEVICE_LOCKED,
  ACTIVITY_TYPE_DEVICE_UNLOCKED,
  ACTIVITY_TYPE_DEVICE_LOCKED_USER,
  ACTIVITY_TYPE_DEVICE_UNLOCKED_USER,
  ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS,
  ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL,
  ACTIVITY_TYPE_DEVICE_LOCK_FAIL,
  ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL,
  ACTIVITY_TYPE_USER_REMOVED_FROM_KEY,
  ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION,
  ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER,
  ACTIVITY_TYPE_REMOVED_STAFF,
  ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER,
  ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER,
  ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER,
  ACTIVITY_TYPE_TRANSFER_RIGHTS,
  ACTIVITY_TYPE_CONNECT_DEVICES,
  ACTIVITY_TYPE_DISCONNECT_DEVICES
} from "@/handlers/const";
import {
  ROLE_COMPANY_MANAGER,
  ROLE_LOCATION_MANAGER,
  ROLE_KEY_MANAGER,
  ROLE_DEVICE_INSTALLER,
  ROLE_KEY_PERMANENT,
  ROLE_KEY_TEMPORARY,
  ROLE_COMPANY_OWNER,
} from "@/acl/roles";
import { mapGetters } from "vuex";
import { EventBus } from "@/main.js"
import { USER_PATCH } from "@/store/types/user";
import {
  M_COMPANY_INVITE_ADMIN_FORM,
  M_COMPANY_CHANGE_ROLE_ADMIN_FORM,
  M_COMPANY_ADMIN_FORM,
  M_MEMBER_INVITE_ADMIN_FORM,
  M_LOCATION_FORM_ADMIN,
  M_SALTO_FORM,
  M_KEY_FORM_ADMIN,
  M_DEVICE_ADMIN_FORM,
  M_ADMIN_LOCATION_USER_DELETE,
  M_ADMIN_LOCATION_MULTIPLE_USER_DELETE,
  M_ADMIN_USER_ACCESS_DELETE,
  M_DEVICE_QR,
} from "@/components/modals/types";
import { DEVICE_PATCH, DEVICE_GET } from "@/store/types/devices";
import { SET_MODAL_DATA } from "@/store/types/global";
import ImageUploadModal from "@/components/modals/ImageUpload";
import AddDoorModal from "@/components/modals/AddDoor";
import DisconnectDoorModal from "@/components/modals/DisconnectDoor";
import { Swedish } from "flatpickr/dist/l10n/sv.js";
import flatPickr from "vue-flatpickr-component";
import Select2 from "@/components/common/Select2";
import DeleteUserAdminModal from "@/components/modals/DeleteUserAdmin";
import DeleteCompanyAdminModal from "@/components/modals/DeleteCompanyAdmin";
import DeleteLocationAdminModal from "@/components/modals/DeleteLocationAdmin";
import DeleteDeviceAdminModal from "@/components/modals/DeleteDeviceAdmin";
import DeleteKeyAdminModal from "@/components/modals/DeleteKeyAdmin";
import DeleteKeyUserAdmin from "@/components/modals/DeleteKeyUserAdmin";
import DeleteLocationUserAdminModal from "@/components/modals/DeleteLocationUserAdmin";
import DeleteLocationMultipleUserAdminModal from "@/components/modals/DeleteLocationMultipleUserAdmin";
import DeleteSaltoInstallationModal from "@/components/modals/DeleteSaltoInstallation";
import CreateDeviceAdminModal from "@/components/modals/CreateDeviceAdmin";
import CreateCompanyAdminModal from "@/components/modals/CreateCompanyAdmin"
import CreateKeyAdminModal from "@/components/modals/CreateKeyAdmin";
import ListTransition from "@/components/common/ListTransition";
import UserCompanyItemAdmin from "@/components/user/UserCompanyItemAdmin";
import CreateLocationAdminModal from "@/components/modals/CreateLocationAdmin";
import CreateSaltoInstallationModal from "@/components/modals/CreateSaltoInstallation";
import EmptyList from "@/components/common/EmptyList";
import LocationListItemAdmin from "@/components/location/LocationListItemAdmin";
import DoorCardItemAdmin from "@/components/device/DoorCardItemAdmin";
import DoorLargeCardItemAdmin from "@/components/device/DoorLargeCardItemAdmin";
import EmployeeListItemAdmin from "@/components/user/EmployeeListItemAdmin";
import KeyListItemAdmin from "@/components/key/KeyListItemAdmin";
import SaltoListItem from "@/components/salto/SaltoListItem";
import Timeline from "@/components/activity/Timeline";
import activityMixin from "@/mixins/activityMixin";
import CancelInviteMemberAdminModal from "@/components/modals/CancelInviteMemberAdmin"
import ShareKeyAdminModal from "@/components/modals/ShareKeyAccordionAdmin";
import InviteCompanyEmployeeAccordionAdminModal from '@/components/modals/InviteCompanyEmployeeAccordionAdmin'
import ChangeLocationEmployeeRoleAdmin from "@/components/modals/ChangeLocationEmployeeRoleAdmin"
import ChangeCompanyEmployeeRoleAdmin from "@/components/modals/ChangeCompanyEmployeeRoleAdmin"
import ChangeOwnerModal from "@/components/modals/ChangeOwner";
import UserAccessDeleteModal from "@/components/modals/UserAccessDelete"
import { validationMixin } from "vuelidate";
import modalMixin from "@/mixins/modalMixin";

import {
  ADMIN_USERS_GET,
  ADMIN_DEVICES_GET,
  ADMIN_KEYS_GET,
  ADMIN_LOCATIONS_GET,
  ADMIN_COMPANIES_GET,
  ADMIN_STAFF_GET,
  ADMIN_ACTIVITY_GET,
  ADMIN_KEY_COMPANIES_GET,
  ADMIN_KEY_LOCATIONS_GET,
  ADMIN_USER_LOCATIONS_GET,
  ADMIN_KEY_DEVICES_GET,
  ADMIN_COMPANY_STAFF_GET,
  ADMIN_UNIQUE_KEY_GET,
  REGISTERED_USERS_GET,
  ADMIN_COMPANY_PATCH,
  ADMIN_LOCATION_PATCH,
  ADMIN_DEVICE_PATCH
} from "@/store/types/admin";
import { SALTO_INSTALLATION_GET, SALTO_INSTALLATION_LOCATIONS_GET, SALTO_INSTALLATION_COMPANIES_GET,SALTO_ONLINE_DEVICE_GET, SALTO_ONLINE_DEVICE_CREATE, SALTO_ERROR } from "@/store/types/salto";
import DeviceQRModal from "@/components/modals/DeviceQR";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import $ from 'jquery'

export default {
  mixins: [validationMixin, modalMixin, activityMixin],
  data: function () {
    return {
      userList: [], // || JSON.parse(this.$session.get("userList")),
      disconnectUserList: [],
      user: {},
      validPhone: false,
      fullPhone: null,
      submitted: false,
      activeTab: 0,
      activeTabTitle: 0,
      locale: Swedish,
      // sortOrderCompany: "asc",
      sortOrderCompany: 1,
      sortOrderLocation: 1,
      sortOrderKey: 1,
      sortOrderDoor: 1,
      sortOrderUser: 1,
      sortOrderSalto: 1,
      sortOrderStaff: 1,
      sortOrderActivity: 1,
      isMobile: false,
      width: window.innerWidth,
      height: window.innerHeight,
      showUserFilters: false,
      showKeyFilters: false,
      showSaltoFilters: false,
      showDoorFilters: false,
      showActivityFilters: false,
      showCompanyLoader: true,
      showLocationLoader: true,
      showRolesLoader: true,
      showUsersLoader: true,
      showStaffLoader: true,
      showDeviceLoader: true,
      showkeyLoader: true,
      showSaltoLoader: true,
      showActivityLoader: true,
      usersFilterBoxDisable: false,
      deviceFilterBoxDisable: false,
      keyFilterBoxDisable: false,
      saltoFilterBoxDisable: false,
      activityFilterBoxDisable: false,
      activity: [],
      viewMode: "",
      mode: 0,
      avatar: null,
      saving: false,
      saved: false,
      currentAdminPage: 1,
      currentPageCompany: 1,
      currentPageLocation: 1,
      currentPageUser: 1,
      currentPageDevice: 1,
      currentPageKey: 1,
      currentPageStaff: 1,
      currentPageSalto: 1,
      filterCompany: "",
      filterLocation: "",
      filterUser: "",
      filterStaff: "",
      filterDoor: "",
      filterKey: "",
      filterSalto: "",
      filterActivity: "",
      filter: "",
      companySelected: null,
      companySelectedStaff: null,
      selectedCompanyId: null,
      fields: [
                {
                    key: "User",
                    label: this.$i18n.t("users.name"),
                    sortable: true,
                },
                {
                    key: "User.PhoneNumber",
                    label: this.$i18n.t("users.phone"),
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "",
                    sortable: false,
                },
            ],
      selectedUsersFilter: { name: "activity.filter_all", key: "all" },
      usersFilters: [
        {
          name: "activity.filter_all",
          key: "all",
        },
        {
          name: "activity.filter_location",
          key: "LocationId",
        },
        {
          name: "activity.filter_user",
          key: "UserId",
        },
      ],
      selectedUsersFilterValue: '',
      usersFilter: '',
      selectedDeviceFilter: { name: "activity.filter_all", key: "all" },
      deviceFilters: [
        {
          name: "activity.filter_all",
          key: "all",
        },
        {
          name: "activity.filter_company",
          key: "CompanyId",
        },
        {
          name: "activity.filter_location",
          key: "LocationId",
        },
        {
          name: "activity.filter_key",
          key: "KeyId",
        },
      ],
      selectedDeviceFilterValue: '',
      deviceFilter: '',
      selectedKeyFilter: { name: "activity.filter_all", key: "all" },
      keyFilters: [
        {
          name: "activity.filter_all",
          key: "all",
        },
        {
          name: "activity.filter_company",
          key: "CompanyId",
        },
        {
          name: "activity.filter_location",
          key: "LocationId",
        },
        {
          name: "activity.filter_door",
          key: "DeviceId",
        },
        /* {
          name: "activity.filter_key",
          key: "KeyId",
        }, */
      ],
      selectedKeyFilterValue: '',
      keyFilter: '',
      selectedSaltoFilter: { name: "activity.filter_all", key: "all" },
      saltoFilters: [
        {
          name: "activity.filter_all",
          key: "all",
        },
        {
          name: "activity.filter_company",
          key: "CompanyId",
        },
        {
          name: "activity.filter_location",
          key: "LocationId",
        },
      ],
      selectedSaltoFilterValue: '',
      saltoFilter: '',
      activityFlag: true,
      companySelect: true,
      perAdminPage: 20,
      perPageCompany: 20,
      perPageLocation: 20,
      perPageUser: 20,
      perPageDevice: 20,
      perPageKey: 20,
      perPageStaff: 20,
      perPageSalto: 20,
      perPageOptions: [
        { id: 10, text: "10 " + this.$t("menu.per_page") },
        { id: 20, text: "20 " + this.$t("menu.per_page") },
        { id: 50, text: "50 " + this.$t("menu.per_page") },
      ],
      sortOrderOptions: [
        { id: 1, text: "Asc"},
        { id: 2, text: "Desc"},
      ],
      flatpickrRange: [
        this.$moment().subtract(7, "days").toDate(),
        this.$moment().toDate(),
      ],
      selectedRange: {
        value: [
          this.$moment().subtract(7, "days").toDate(),
          this.$moment().toDate(),
        ],
        name: "activity.range_custom",
        changeable: true,
        index: 0,
      },
      ranges: [
        {
          value: [
            // this.$moment().subtract(90, "days").toDate(),
            this.$moment().subtract(7, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_custom",
          changeable: true,
          index: 0,
        },
        {
          value: [
            this.$moment().subtract(7, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_7_days",
          changeable: false,
          index: 1,
        },
        {
          value: [
            this.$moment().subtract(30, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_30_days",
          changeable: false,
          index: 2,
        },
        {
          value: [
            this.$moment().subtract(90, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_90_days",
          changeable: false,
          index: 3,
        },
        {
          value: [
            this.$moment().subtract(365, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_year",
          changeable: false,
          index: 4,
        },
        /* {
          value: [
            this.$moment().startOf("week").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_week",
          changeable: false,
          index: 3,
        },
        {
          value: [
            this.$moment().startOf("month").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_month",
          changeable: false,
          index: 4,
        }, */
      ],
      maxDate: new Date(),
      selectedFilter: { name: "activity.filter_all", key: "all" },
      filters: [
        {
          name: "activity.filter_all",
          key: "all",
        },
        {
          name: "activity.filter_phone",
          key: "PhoneNumber",
        },
        /* {
          name: "activity.filter_manager",
          key: "Manager",
        }, */
        {
          name: "activity.filter_user",
          key: "UserId",
        },
        {
          name: "activity.filter_key",
          key: "KeyId",
        },
        {
          name: "activity.filter_door",
          key: "DeviceId",
        },
        {
          name: "activity.filter_company",
          key: "CompanyId",
        },
        {
          name: "activity.filter_location",
          key: "LocationId",
        },
      ],
      selectedFilterValue: '',
      locationVal: 0,
      filterVal: '',
      checkBoxFlag: false,
      locationMembersList: [],
      yearRange: false,
    };
  },
  validations: {
    user: {
      FirstName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30),
      },
      LastName: {
        minLength: minLength(3),
        maxLength: maxLength(30),
      },
      PhoneNumber: {
        required,
      },
      Email: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters([
      "getAllUsers",
      "getAllRegisteredUsers",
      "getUserRoles",
      "getAllDevices",
      "getAllKeys",
      "getAllAdminCompanies",
      "getCompanyStaff",
      "getUniqueKey",
      "getAllLocations",
      "getAllStaff",
      "getAllActivity",
      "getActiveCompany",
      "getActiveLocation",
      "getCompanyEmployees",
      "getSaltoInstallation",
      "getAllSaltoCompanies",
      "getAllSaltoLocations",
      "getAllKeyCompanies",
      "getAllKeyLocations",
      "getAllKeyDevices",
      "getAllUsersLocations",
    ]),
    filterDeviceValuesList() {
      const defaultValue = {
        text: this.$t("activity.filter_all"),
        id: 0,
      };
      switch (this.selectedDeviceFilter.key) {
        case "CompanyId": {
          return [
            defaultValue,
            ...this.getAllKeyCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return { text: item.Name, id: item.Id };
            }),
            /* ...this.getAllAdminCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              if(item.Name.length > 20){
                item.Name = item.Name.slice(0, 16).concat('...');
              }
              return { text: item.Name, id: item.Id };
            }), */
          ];
        }
        case "LocationId": {
          return [
            defaultValue,
            ...this.getAllKeyLocations
              .filter(
                (item) =>
                  item.Name.toLowerCase().includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                return {
                  text: item.Name,
                  id: item.Id,
                };
              }),
            /* ...this.getAllLocations
              .filter(
                (item) =>
                  item.Location.Name.toLowerCase().includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                return {
                  text: item.Location.Name,
                  id: item.Location.Id,
                };
              }), */
          ];
        }
        case "KeyId": {
          return [
            defaultValue,
            // ...this.getAllKeyDevices
            ...this.getUniqueKey
              .filter(
                (item) =>
                  item.Key.Name.toLowerCase().includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Key.Name.toUpperCase() > i2.Key.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                if(item.Key.Name.includes("Key of: ")){
                  item.Key.Name = item.Key.Name.replace("Key of: ","");
                }
                return {
                  text: item.Key.Name,
                  id: item.Key.Id,
                };
              }),
          ];
        }
        default:
          return [];
      }
    },
    filterKeyValuesList() {
      const defaultValue = {
        text: this.$t("activity.filter_all"),
        id: 0,
      };
      switch (this.selectedKeyFilter.key) {
        case "CompanyId": {
          return [
            defaultValue,
            ...this.getAllKeyCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return { text: item.Name, id: item.Id };
            }),
          ];
        }
        case "DeviceId": {
          return [
            defaultValue,
            ...this.getAllKeyDevices
            .filter((item) =>
                item.Device.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return {
                text: item.Device.Name,
                id: item.Device.Id,
              };
            }),
          ];
        }
        case "LocationId": {
          return [
            defaultValue,
            ...this.getAllKeyLocations
              .filter(
                (item) =>
                  item.Name.toLowerCase().includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                return {
                  text: item.Name,
                  id: item.Id,
                };
              }),
          ];
        }
        default:
          return [];
      }
    },
    filterSaltoValuesList() {
      const defaultValue = {
        text: this.$t("activity.filter_all"),
        id: 0,
      };
      switch (this.selectedSaltoFilter.key) {
        case "CompanyId": {
          return [
            defaultValue,
            ...this.getAllSaltoCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return { text: item.Name, id: item.Id };
            }),
          ];
        }
        case "Id": {
          return [
            defaultValue,
            ...this.getSaltoInstallation
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
               return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return {
                text: item.Name,
                id: item.Name,
              };
            }),
          ];
        }
        case "LocationId": {
          return [
            defaultValue,
            ...this.getAllSaltoLocations
              .filter(
                (item) =>
                  item.Name.toLowerCase().includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                return {
                  text: item.Name,
                  id: item.Id,
                };
              }),
          ];
        }
        case "SaltoInstallationId": {
          return [
            defaultValue,
            ...this.getSaltoInstallation
            .filter((item) =>
                item.SaltoInstallationId
            )
            .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return {
                text: item.SaltoInstallationId,
                id: item.SaltoInstallationId,
              };
            }),
          ];
        }
        default:
          return [];
      }
    },
    locationUsersList() {
      if(this.$session.get("userFlag") == true){
        this.checkBoxFlag = false;
        this.userList = [];
      }
            return this.getAllUsers
                .filter((item) => {
                    let filterUserListVal = true;
                    if(this.selectedUsersFilterValue != ''){
                      if(this.selectedUsersFilterValue == 0){
                        this.checkBoxFlag = false
                        filterUserListVal = item.User.Name.toLowerCase().includes(this.filterUser.toLowerCase())
                      }else{
                        /* filterUserListVal = item.User.Id == this.selectedUsersFilterValue || item.Location.Id == this.selectedUsersFilterValue
                        if(this.filterUser != ''){
                          filterUserListVal = (item.User.Id == this.selectedUsersFilterValue || item.Location.Id == this.selectedUsersFilterValue ) &&  (item.User.Name.toLowerCase().includes(this.filterUser.toLowerCase()))
                        } */

                        if(this.selectedUsersFilter.key == "UserId"){
                          filterUserListVal = item.User.Id == this.selectedUsersFilterValue
                          this.checkBoxFlag = false
                          if(this.filterUser != ''){
                            filterUserListVal = (item.User.Id == this.selectedUsersFilterValue) && (item.User.Name.toLowerCase().includes(this.filterUser.toLowerCase()))
                          }
                        }else{
                          filterUserListVal = item.Location.Id == this.selectedUsersFilterValue
                          this.checkBoxFlag = true
                          if(this.filterUser != ''){
                            filterUserListVal = ( item.Location.Id == this.selectedUsersFilterValue ) && (item.User.Name.toLowerCase().includes(this.filterUser.toLowerCase()))
                          }
                        }
                      }
                    }else{
                      filterUserListVal = item.User.Name.toLowerCase().includes(this.filterUser.toLowerCase())
                    }
                    return filterUserListVal
            })
            .sort((i1, i2) => {
              if (this.sortOrderUser == 1) {
                return i1.User.Name.toUpperCase() > i2.User.Name.toUpperCase() ? 1 : -1;
              } else {
                return i1.User.Name.toUpperCase() < i2.User.Name.toUpperCase() ? 1 : -1;
              }
            })
    },
    paginatedUserInstallationList() {
      return this.locationUsersList.slice(
        (this.currentPageUser - 1) * this.perPageUser,
        this.currentPageUser * this.perPageUser
      );
    },
    getUserRolesFiltered() {
      return this.getUserRoles.filter(
        (item) => ![ROLE_KEY_PERMANENT, ROLE_KEY_TEMPORARY].includes(item.Name)
      );
    },
    getSaltoInstallationList(){
            return this.getSaltoInstallation
                    .filter((item) => {
                      let filterSaltoInstallation = true;
                      if(this.selectedSaltoFilterValue != ''){
                        if(this.selectedSaltoFilterValue == 0){
                          filterSaltoInstallation = item.Name.toLowerCase().includes(this.filterSalto.toLowerCase())
                        }else{
                          filterSaltoInstallation = item.CompanyId == this.selectedSaltoFilterValue || item.LocationId == this.selectedSaltoFilterValue || item.SaltoInstallationId == this.selectedSaltoFilterValue || item.Name.toLowerCase().includes(this.selectedSaltoFilterValue.toLowerCase())
                          if(this.filterSalto != ''){
                            filterSaltoInstallation = (item.CompanyId == this.selectedSaltoFilterValue || item.LocationId == this.selectedSaltoFilterValue || item.SaltoInstallationId == this.selectedSaltoFilterValue || item.Name.toLowerCase().includes(this.selectedSaltoFilterValue.toLowerCase())) && (item.Name.toLowerCase().includes(this.filterSalto.toLowerCase()))
                          }
                        }
                      }else{
                        filterSaltoInstallation = item.Name.toLowerCase().includes(this.filterSalto.toLowerCase())
                      }
                      return filterSaltoInstallation
                    })
                    .sort((i1, i2) => {
                        if (this.sortOrderSalto == 1) {
                          return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                        } else {
                          return i1.Name.toUpperCase() < i2.Name.toUpperCase() ? 1 : -1;
                        }
                    });
        },
    paginatedSaltoInstallationList() {
      return this.getSaltoInstallationList.slice(
        (this.currentPageSalto - 1) * this.perPageSalto,
        this.currentPageSalto * this.perPageSalto
      );
    },
    companiesList() {
      return this.getAllAdminCompanies
      .filter(
          (item) =>
            item.Name.toLowerCase().includes(this.filterCompany.toLowerCase())
        )
        .sort((i1, i2) => {
              if (this.sortOrderCompany == 1) {
                return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
              } else {
                return i1.Name.toUpperCase() < i2.Name.toUpperCase() ? 1 : -1;
              }
            });
    },
    companiesOptions() {
      return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                ...this.getAllAdminCompanies
                .filter((item) =>
                    item.Name
                    .toLowerCase()
                    .includes(this.filterCompany.toLowerCase())
                )
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    /* if(item.Name.length > 20){
                      item.Name = item.Name.slice(0, 16).concat('...');
                    } */
                    return {
                        id: item.Id,
                        text: item.Name,
                    };
                }),
            ];
    },
    companiesOptionsStaff() {
      return [
                {
                    value: null,
                    text: this.$t("locations.company_select"),
                    disabled: true,
                },
                // ...this.getAllAdminCompanies
                ...this.getCompanyStaff
                .filter((item) =>
                    item.Name
                    .toLowerCase()
                    .includes(this.filterStaff.toLowerCase())
                )
                .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
                })
                .map((item) => {
                    return {
                        id: item.Id,
                        text: item.Name,
                    };
                }),
            ];
    },
    locationsList() {
        return this.getAllLocations
        .filter(
          (item) =>{
            let filterLoc = true;
            if(this.companySelected != null){
              filterLoc = item.Location.CompanyId == this.companySelected
              if(this.filterLocation != ''){
                if(item.Location.Name != null || item.Location.Name != 'null' || item.Location.Name != '' || item.Location.Address != null || item.Location.Address != 'null' || item.Location.Address != ''){
                  filterLoc = (item.Location.CompanyId == this.companySelected) && (item.Location.Name.toLowerCase().includes(this.filterLocation.toLowerCase()) ||
                  item.Location.Address.toLowerCase().includes(this.filterLocation.toLowerCase()))
                }
              }
            }else if(item.Location.Name != null || item.Location.Name != 'null' || item.Location.Name != '' || item.Location.Address != null || item.Location.Address != 'null' || item.Location.Address != ''){
              filterLoc = item.Location.Name.toLowerCase().includes(this.filterLocation.toLowerCase()) ||
              item.Location.Address.toLowerCase().includes(this.filterLocation.toLowerCase())
            }
            this.$session.set("locationSelected", item.Location.Id);
            return filterLoc;
        })
        .sort((i1, i2) => {
          if (this.sortOrderLocation == 1) {
            return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
          } else {
            return i1.Location.Name.toUpperCase() < i2.Location.Name.toUpperCase() ? 1 : -1;
          }
        });
    },
    getCompanyEmployeesFiltered() {
      return (
        this.getAllStaff
          .filter((item) =>{
              let filterStaffList = true;
              if(this.companySelectedStaff !== null){
                // console.log("this.companySelectedStaff:"+this.companySelectedStaff)
                if(item.Company != null) {
                  filterStaffList = item.Company.Id == this.companySelectedStaff
                }else if(item.Location != null){
                  filterStaffList = item.Location.CompanyId == this.companySelectedStaff
                }
                if(this.filterStaff != ''){
                  if(item.Company != null) {
                    filterStaffList = (item.Company.Id == this.companySelectedStaff) && (item.User.FirstName.toLowerCase().includes(this.filterStaff.toLowerCase()))
                  }else if(item.Location != null){
                    filterStaffList = (item.Location.CompanyId == this.companySelectedStaff) && (item.User.FirstName.toLowerCase().includes(this.filterStaff.toLowerCase()))
                  }
                }
              }else if(item.User.FirstName != null || item.User.FirstName != 'null' || item.User.FirstName != ''){
               filterStaffList =  item.User.FirstName.toLowerCase().includes(this.filterStaff.toLowerCase())
                // filterStaffList =  item.User.FirstName.includes(this.filterStaff)
              }
              return filterStaffList;
          })
          .sort((i1, i2) => {
            if(i1.User.FirstName != null || i1.User.FirstName != 'null' || i1.User.FirstName != ''){
              if (this.sortOrderStaff == 1) {
                return i1.User.FirstName.toUpperCase() > i2.User.FirstName.toUpperCase() ? 1 : -1;
              } else {
                return i1.User.FirstName.toUpperCase() < i2.User.FirstName.toUpperCase() ? 1 : -1;
              }
            }else if(i1.User.LastName != null || i1.User.LastName != 'null' || i1.User.LastName != ''){
              if (this.sortOrderStaff == 1) {
                return i1.User.LastName.toUpperCase() > i2.User.LastName.toUpperCase() ? 1 : -1;
              } else {
                return i1.User.LastName.toUpperCase() < i2.User.LastName.toUpperCase() ? 1 : -1;
              }
            }
          })
      );
    },
    paginatedStaffList() {
      return this.getCompanyEmployeesFiltered.slice(
        (this.currentPageStaff - 1) * this.perPageStaff,
        this.currentPageStaff * this.perPageStaff
      );
    },
    devicesList() {
      return this.getAllDevices
        .filter((item) =>
          {
            let filterDevice = true;
            if(this.selectedDeviceFilterValue != ''){
              if(this.selectedDeviceFilter.key == "KeyId"){
                for(let i=0;i<item.Keys.length;i++){
                  if((item.Keys[i].KeyId != null) && (item.Keys[i].KeyId == this.selectedDeviceFilterValue)){
                    if(this.filterDoor != ''){
                      return filterDevice = item.Device.Name.toLowerCase().includes(this.filterDoor.toLowerCase())
                    }else{
                      return filterDevice = item
                    }
                  }
                }
              }
              if(this.selectedDeviceFilterValue == 0){
                filterDevice = item.Device.Name.toLowerCase().includes(this.filterDoor.toLowerCase())
              }else{
                /* filterDevice = item.Device.CompanyId == this.selectedDeviceFilterValue || item.Device.LocationId == this.selectedDeviceFilterValue || item.Device.Id == this.selectedDeviceFilterValue
                  if(this.filterDoor != ''){
                    filterDevice = (item.Device.CompanyId == this.selectedDeviceFilterValue || item.Device.LocationId == this.selectedDeviceFilterValue || item.Device.Id == this.selectedDeviceFilterValue) && (item.Device.Name.toLowerCase().includes(this.filterDoor.toLowerCase()))
                  } */
                  filterDevice = item.Device.CompanyId == this.selectedDeviceFilterValue || item.Device.LocationId == this.selectedDeviceFilterValue
                  if(this.filterDoor != ''){
                    filterDevice = (item.Device.CompanyId == this.selectedDeviceFilterValue || item.Device.LocationId == this.selectedDeviceFilterValue) && (item.Device.Name.toLowerCase().includes(this.filterDoor.toLowerCase()))
                  }
              }
            }else{
              filterDevice = item.Device.Name.toLowerCase().includes(this.filterDoor.toLowerCase())
            }
            return filterDevice
        })
        .sort((i1, i2) => {
          if (this.sortOrderDoor == 1) {
            return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase()
              ? 1
              : -1;
          } else {
            return i1.Device.Name.toUpperCase() < i2.Device.Name.toUpperCase()
              ? 1
              : -1;
          }
        });
    },
    paginatedList() {
      return this.devicesList.slice(
        (this.currentPageDevice - 1) * this.perPageDevice,
        this.currentPageDevice * this.perPageDevice
      );
    },
    paginatedLocationList() {
      return this.locationsList.slice(
        (this.currentPageLocation - 1) * this.perPageLocation,
        this.currentPageLocation * this.perPageLocation
      );
    },
    keysList() {
      return this.getAllKeys
        .filter((item) =>{
          let filterKeyVal = true;
          // || item.Devices.DeviceId == this.selectedKeyFilterValue

          if(this.selectedKeyFilterValue != ''){
            // console.log("this.selectedKeyFilterValue:"+this.selectedKeyFilterValue)
              if(this.selectedKeyFilter.key == "DeviceId"){
                for(let i=0;i<item.Devices.length;i++){
                  if((item.Devices[i].DeviceId != null) && (item.Devices[i].DeviceId == this.selectedKeyFilterValue)){
                    if(this.filterKey != ''){
                      return filterKeyVal = item.Key.Name.toLowerCase().includes(this.filterKey.toLowerCase())
                    }else{
                      return filterKeyVal = item
                    }
                  }
                }
              }
              if(this.selectedKeyFilterValue == 0){
                filterKeyVal = item.Key.Name.toLowerCase().includes(this.filterKey.toLowerCase())
              }else{
                /* filterKeyVal = item.Key.CompanyId == this.selectedKeyFilterValue || item.Key.LocationId == this.selectedKeyFilterValue || item.Key.Id == this.selectedKeyFilterValue
                if(this.filterKey != ''){
                  filterKeyVal = (item.Key.CompanyId == this.selectedKeyFilterValue || item.Key.LocationId == this.selectedKeyFilterValue || item.Key.Id == this.selectedKeyFilterValue ) && (item.Key.Name.toLowerCase().includes(this.filterKey.toLowerCase()))
                }  */
                filterKeyVal = item.Key.CompanyId == this.selectedKeyFilterValue || item.Key.LocationId == this.selectedKeyFilterValue
                if(this.filterKey != ''){
                  filterKeyVal = (item.Key.CompanyId == this.selectedKeyFilterValue || item.Key.LocationId == this.selectedKeyFilterValue) && (item.Key.Name.toLowerCase().includes(this.filterKey.toLowerCase()))
                }
              }
          }else{
            filterKeyVal = item.Key.Name.toLowerCase().includes(this.filterKey.toLowerCase())
          }

          return filterKeyVal
        })
        .sort((i1, i2) => {
          if (this.sortOrderKey == 1) {
            return i1.Key.Name.toUpperCase() > i2.Key.Name.toUpperCase()
              ? 1
              : -1;
          } else {
            return i1.Key.Name.toUpperCase() < i2.Key.Name.toUpperCase()
              ? 1
              : -1;
          }
        });
    },
    paginatedKeyList() {
      return this.keysList.slice(
        (this.currentPageKey - 1) * this.perPageKey,
        this.currentPageKey * this.perPageKey
      );
    },
    paginatedCompanyList() {
      return this.companiesList.slice(
        (this.currentPageCompany - 1) * this.perPageCompany,
        this.currentPageCompany * this.perPageCompany
      );
    },
    activityList(){
      // return this.getAllActivity
      return this.activity
        .filter((item) =>{
          let filterActivityVal = true;
          if(this.selectedFilterValue != ''){
            // console.log("this.selectedFilterValue:"+this.selectedFilterValue)
            if(this.selectedFilterValue == 0){
               filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
            }else{
              filterActivityVal = item.KeyId == this.selectedFilterValue || item.DeviceId == this.selectedFilterValue || item.LocationId == this.selectedFilterValue || item.CompanyId == this.selectedFilterValue || item.User.Id == this.selectedFilterValue
              if(this.filterActivity != ''){
                filterActivityVal = (item.KeyId == this.selectedFilterValue || item.DeviceId == this.selectedFilterValue || item.LocationId == this.selectedFilterValue || item.CompanyId == this.selectedFilterValue || item.User.Id == this.selectedFilterValue) && (item.Name.toLowerCase().includes(this.filterActivity.toLowerCase()))

                if(item.Company != null){
                  filterActivityVal = item.Company.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else if(item.Location != null){
                  filterActivityVal = item.Location.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else if(item.Device != null){
                  filterActivityVal = item.Device.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else if(item.Key != null){
                  filterActivityVal = item.Key.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else if(item.User != null){
                  filterActivityVal = item.User.FirstName.toLowerCase().includes(this.filterActivity.toLowerCase()) || item.User.LastName.toLowerCase().includes(this.filterActivity.toLowerCase())
                }else{
                  filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
                }
              }
            }
          }else{
            filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())

            if(this.filterActivity != ''){
              if(item.Company != null){
                filterActivityVal = item.Company.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else if(item.Location != null){
                filterActivityVal = item.Location.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else if(item.Device != null){
                filterActivityVal = item.Device.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else if(item.Key != null){
                filterActivityVal = item.Key.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else if(item.User != null){
                filterActivityVal = item.User.FirstName.toLowerCase().includes(this.filterActivity.toLowerCase()) || item.User.LastName.toLowerCase().includes(this.filterActivity.toLowerCase())
              }else{
                filterActivityVal = item.Name.toLowerCase().includes(this.filterActivity.toLowerCase())
              }
            }
          }
          if(filterActivityVal == true){
            this.activityFlag = true;
            return filterActivityVal
          }else{
            this.activityFlag = false;
            return
          }
        })
        .sort((i1, i2) => {
           return i1.Time < i2.Time
              ? 1
              : -1;
        });
        /* .sort((i1, i2) => {
           return i1.Name.toUpperCase() > i2.Name.toUpperCase()
              ? 1
              : -1;
        }) */
    },
    paginatedAdminActivityList() {
        return this.activityList.slice(
          (this.currentAdminPage - 1) * this.perAdminPage,
          this.currentAdminPage * this.perAdminPage
        );
    },
    filterUsersValuesList() {
      const defaultValue = {
        text: this.$t("activity.filter_all"),
        id: 0,
      };
      switch (this.selectedUsersFilter.key) {
         case "UserId": {
          this.checkBoxFlag = false
          return [
            defaultValue,
            ...this.getAllUsers
              .filter(
                (item, index, self) =>
                  self.findIndex((t) => t.User.Id === item.User.Id) === index
              )
              .sort((i1, i2) => {
                  return i1.User.FirstName.toUpperCase() > i2.User.FirstName.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                if(item.User.LastName === null){
                  item.User.LastName = ''
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }else{
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }
              }),
          ];
        }
        case "LocationId": {
          return [
            defaultValue,
            ...this.getAllUsersLocations
              .filter(
                (item) =>
                  item.Location.Name.toLowerCase().includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                return {
                  text: item.Location.Name,
                  id: item.Location.Id,
                };
              }),
          ];
        }
        default:
          return [];
      }
    },
    filterValuesList() {
      const defaultValue = {
        text: this.$t("activity.filter_all"),
        id: 0,
      };
      switch (this.selectedFilter.key) {
        case "KeyId": {
          return [
            defaultValue,
            ...this.getAllKeys
            .filter((item) =>
                item.Key.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Key.Name.toUpperCase() > i2.Key.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              if(item.Key.Name.includes("Key of: ")){
                item.Key.Name = item.Key.Name.replace("Key of: ","");
              }
              return { text: item.Key.Name, id: item.Key.Id };
            }),
          ];
        }
        case "DeviceId": {
          return [
            defaultValue,
            ...this.getAllDevices
            .filter((item) =>
                item.Device.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Device.Name.toUpperCase() > i2.Device.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return {
                text: item.Device.Name,
                id: item.Device.Id,
              };
            }),
          ];
        }
        case "UserId": {
          return [
            defaultValue,
            ...this.getAllRegisteredUsers
              .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                if(item.FirstName == null || item.FirstName == undefined || item.FirstName == "null"){
                  item.FirstName = ""
                }
                if(item.LastName == null || item.LastName == undefined || item.LastName == "null"){
                  item.LastName = ""
                }
                 return {
                    text: item.FirstName + " " + item.LastName,
                    id: item.Id,
                  };
              }),
             /* ...this.getAllUsers
              .filter((item) =>
                item.User.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.User.Name.toUpperCase() > i2.User.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                if(item.User.LastName === null){
                  item.User.LastName = ''
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }else{
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }
              }), */
          ];
        }
        case "CompanyId": {
          return [
            defaultValue,
            ...this.getAllAdminCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                    return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            })
            .map((item) => {
              return { text: item.Name, id: item.Id };
            }),
          ];
        }
        case "LocationId": {
          return [
            defaultValue,
            ...this.getAllLocations
              .filter(
                (item) =>
                  item.Location.Name.toLowerCase().includes(this.filter.toLowerCase())
              )
              .sort((i1, i2) => {
                  return i1.Location.Name.toUpperCase() > i2.Location.Name.toUpperCase() ? 1 : -1;
              })
              .map((item) => {
                return {
                  text: item.Location.Name,
                  id: item.Location.Id,
                };
              }),
          ];
        }
        case "PhoneNumber": {
          return [
            defaultValue,
            ...this.getAllRegisteredUsers
              .filter(
                (item, index, self) =>
                  self.findIndex((t) => t.Id === item.Id) === index
              )
              .map((item) => {
                if(item.FirstName == null || item.FirstName == undefined || item.FirstName == "null"){
                  item.FirstName = ""
                }
                if(item.LastName == null || item.LastName == undefined || item.LastName == "null"){
                  item.LastName = ""
                }
                return {
                  text: item.PhoneNumber,
                  id: item.Id,
                };
              }),
             /* ...this.getAllUsers
              .filter(
                (item, index, self) =>
                  self.findIndex((t) => t.User.Id === item.User.Id) === index
              )
              .map((item) => {
                return {
                  text: item.User.PhoneNumber,
                  id: item.User.Id,
                };
              }), */
          ];
        }
        case "Manager": {
          return [
            defaultValue,
            ...this.getAllStaff
              .filter((item) =>
                ((item.User.FirstName + " " + item.User.LastName)
                  .toLowerCase()
                  .includes(this.filter.toLowerCase()))  && (item.Role.Name == "CompanyManager"))
              .map((item) => {
                if(item.User.LastName === null){
                  item.User.LastName = ''
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }else{
                  return {
                    text: item.User.FirstName + " " + item.User.LastName,
                    id: item.User.Id,
                  };
                }
              }),
          ];
        }
        default:
          return [];
      }
    },
  },
  created() {
    this.isMobile = window.addEventListener("resize", this.onResize);
    this.isMobile = window.addEventListener("onload", this.onLoad);
    if (this.$session.get("pageView")) {
      this.viewMode = this.$session.get("pageView");
    } else {
      this.viewMode = "list";
    }
    if (this.$session.get("activeTabView")) {
      this.activeTab = this.$session.get("activeTabView");
    } else {
      this.activeTab = 0;
    }
    if (this.$session.get("activeTextView")) {
      this.activeTabTitle = this.$session.get("activeTextView");
    } else {
      this.activeTabTitle = 0;
    }
    this.initDevice();
    this.initCompany();
    this.initLocation();
    this.initUser();
    this.initKey();
    this.initStaff();
    this.initSaltoInstallation();
    this.initActivity();
    this.initFilters();
    this.$store
      .dispatch(SALTO_INSTALLATION_COMPANIES_GET);
    this.$store
      .dispatch(SALTO_INSTALLATION_LOCATIONS_GET);
    this.$store
      .dispatch(ADMIN_KEY_COMPANIES_GET);
    this.$store
      .dispatch(ADMIN_KEY_LOCATIONS_GET);
    this.$store
      .dispatch(ADMIN_USER_LOCATIONS_GET);
    this.$store
      .dispatch(ADMIN_KEY_DEVICES_GET);
    this.$store.dispatch(ADMIN_COMPANY_STAFF_GET);
    this.$store.dispatch(ADMIN_UNIQUE_KEY_GET);
    this.$store
      .dispatch(REGISTERED_USERS_GET);
    this.$store
      .dispatch(SALTO_INSTALLATION_GET)
      .then(() => (this.showSaltoLoader = false));
    this.$store
      .dispatch(ADMIN_DEVICES_GET)
      .then(() => (this.showDeviceLoader = false));
    this.$store
      .dispatch(ADMIN_USERS_GET)
      .then(() => (this.showUsersLoader = false));
    this.$store
      .dispatch(ADMIN_KEYS_GET)
      .then(() => (this.showkeyLoader = false));
    this.$store
      .dispatch(ADMIN_COMPANIES_GET)
      .then(() => (this.showCompanyLoader = false));
    this.$store
      .dispatch(ADMIN_LOCATIONS_GET)
      .then(() => (this.showLocationLoader = false));
    this.$store
      .dispatch(ADMIN_STAFF_GET)
      .then(() => (this.showStaffLoader = false));
    this.$store
      .dispatch(ADMIN_ACTIVITY_GET)
      .then(() => (this.showActivityLoader = false));
  },
  methods: {
    disconnectUser(){
      // console.log("this.userList:"+this.userList.length)
      // return false
      // return this.userList;
    },
    updateCompanyImage(response) {
      this.company = this.$session.get("company");
            /* this.$store
                .dispatch(ADMIN_COMPANY_PATCH, {
                ...this.company,
                ...{ ImageId: response.Id },
                })
                .then(() => {
                  this.$store.dispatch(ADMIN_COMPANIES_GET);
                })
                .catch((error) => {}); */
            if(this.company.Owner.FirstName == null || this.company.Owner.FirstName == "null" || this.company.Owner.FirstName == "" || this.company.Owner.FirstName == undefined){
                    this.company.Owner.FirstName = '';
                }
                if(this.company.Owner.LastName == null || this.company.Owner.LastName == "null" || this.company.Owner.LastName == "" || this.company.Owner.LastName == undefined){
                    this.company.Owner.LastName = '';
                }
                this.company.OwnerName = this.company.Owner.FirstName +" "+ this.company.Owner.LastName;
            let payload = {
                ...this.company,
                ...{ OwnerPhoneNumber: this.company.Owner.PhoneNumber },
                ...{ CompanyPhoneNumber: this.company.PhoneNumber },
                ...{ ImageId: response.Id },
            };
            this.$store
                .dispatch(ADMIN_COMPANY_PATCH, payload)
                .then(() => {
                  this.$store.dispatch(ADMIN_COMPANIES_GET);
                })
                .catch((error) => {});
    },
    updateLocationImage(response) {
            this.location = this.$session.get("location");
            this.$store
                .dispatch(ADMIN_LOCATION_PATCH, {
                    ...this.location.Location,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                    this.$store.dispatch(ADMIN_LOCATIONS_GET);
                })
                .catch((error) => {});
        },
    updateDoorImage(response) {
      this.device = this.$session.get("device");
            this.$store
                .dispatch(ADMIN_DEVICE_PATCH, {
                    ...this.device,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                   this.$store.dispatch(ADMIN_DEVICES_GET);
                })
                .catch((error) => {});
        },
    imageUploaded(response) {
            this.submitted = true;
            this.$v.user.$touch();
            if (this.$v.user.$anyError) {
                return;
            }
            let payload = {
                ...this.user,
                ...{ ImageId: response.Id },
            };
            if(response.Name.includes("company_avatar")){
                    let cid = this.$session.get("companyId")
                   /* this.company = this.$session.get("company");
                        this.$store
                        .dispatch(ADMIN_COMPANY_PATCH, {
                        ...this.company,
                        ...{ ImageId: response.Id },
                        })
                        .then(() => {
                            this.$store.dispatch(COMPANY_GET, this.$session.get("companyId"));
                        })
                        .catch((error) => {}); */
                    if(this.company.Owner.FirstName == null || this.company.Owner.FirstName == "null" || this.company.Owner.FirstName == "" || this.company.Owner.FirstName == undefined){
                        this.company.Owner.FirstName = '';
                    }
                    if(this.company.Owner.LastName == null || this.company.Owner.LastName == "null" || this.company.Owner.LastName == "" || this.company.Owner.LastName == undefined){
                        this.company.Owner.LastName = '';
                    }
                    this.company.OwnerName = this.company.Owner.FirstName +" "+ this.company.Owner.LastName;
                    let payload = {
                        ...this.company,
                        ...{ OwnerPhoneNumber: this.company.Owner.PhoneNumber },
                        ...{ CompanyPhoneNumber: this.company.PhoneNumber },
                        ...{ ImageId: response.Id },
                    };
                    this.$store
                        .dispatch(ADMIN_COMPANY_PATCH, payload)
                        .then(() => {
                          this.$store.dispatch(ADMIN_COMPANIES_GET);
                        })
                        .catch((error) => {});
            }if(response.Name.includes("location_avatar")){
                let lid = this.$session.get("locationId")
                this.$store.dispatch(LOCATION_GET, lid).then((responseLocation) => {
                  this.location = responseLocation
                  this.$store
                        .dispatch(ADMIN_LOCATION_PATCH, {
                            ...this.location,
                            ...{ ImageId: response.Id },
                        })
                        .then(() => {
                            // this.$store.dispatch(LOCATION_GET,lid);
                            // this.$store.dispatch(LOCATIONS_GET_COMPANY_LOCATIONS, this.$route.params.companyId)
                        })
                        .catch((error) => {});
                });
            }else{
              this.$store
                .dispatch(ADMIN_DEVICE_PATCH, {
                    ...this.device.Device,
                    ...{ ImageId: response.Id },
                })
                .then(() => {
                })
                .catch((error) => {});
            }
            /* else{
                this.$store
                .dispatch(USER_PATCH, payload)
                .then((response) => {
                    this.$bvModal.hide(this.type);
                })
                .catch((error) => {});
            } */
        },
    pageView(view) {
      this.$session.set("pageView", view);
      this.viewMode = view;
    },
    activeTabView(val) {
      this.$session.set("activeTabView", val);
      if(val == 0){
        this.$session.set("activeTextView", this.$t("admin.all_companies"));
        EventBus.$emit("tabName", this.$t("admin.all_companies"));
      }else if(val == 1){
        this.$session.set("activeTextView", this.$t("menu.all_locations"));
        EventBus.$emit("tabName", this.$t("menu.all_locations"));
      }else if(val == 2){
        this.$session.set("activeTextView", this.$t("admin.all_users"));
        EventBus.$emit("tabName", this.$t("admin.all_users"));
      }else if(val == 3){
        this.$session.set("activeTextView", this.$t("menu.all_doors"));
        EventBus.$emit("tabName",  this.$t("menu.all_doors"));
      }else if(val == 4){
        this.$session.set("activeTextView", this.$t("menu.all_keys"));
        EventBus.$emit("tabName",  this.$t("menu.all_keys"));
      }else if(val == 5){
        this.$session.set("activeTextView", this.$t("menu.staff"));
        EventBus.$emit("tabName",  this.$t("menu.staff"));
      }else if(val == 6){
        this.$session.set("activeTextView", this.$t("doors.saltoInstallation"));
        EventBus.$emit("tabName",  this.$t("doors.saltoInstallation"));
      }else if(val == 7){
        this.$session.set("activeTextView", this.$t("admin.admin_activity"));
        EventBus.$emit("tabName",  this.$t("admin.admin_activity"));
      }
      // EventBus.$emit("tabName", this.$session.get("activeTextView"))
      EventBus.$emit("tabVal", val)
      this.activeTab = val;
    },
    filterUserStatus(status){
      // console.log("this.selectedUsersFilter.key:"+this.selectedUsersFilter.key)
      if(!status){
        this.selectedUsersFilterValue = '';
        this.usersFilterBoxDisable = false;
        this.selectedUsersFilter = { name: "activity.filter_all", key: "all" };
        this.usersFilter = '';
        if(this.selectedUsersFilter.key == "UserId"){
          this.checkBoxFlag = false
        }
      }
    },
    filterDeviceStatus(status){
      if(!status){
        this.selectedDeviceFilterValue = '';
        this.deviceFilterBoxDisable = false;
        this.selectedDeviceFilter = { name: "activity.filter_all", key: "all" };
        this.deviceFilter = '';
      }
    },
    filterKeyStatus(status){
      if(!status){
        this.selectedKeyFilterValue = '';
        this.keyFilterBoxDisable = false;
        this.selectedKeyFilter = { name: "activity.filter_all", key: "all" };
        this.keyFilter = '';
      }
    },
    filterSaltoStatus(status){
      if(!status){
        this.selectedSaltoFilterValue = '';
        this.saltoFilterBoxDisable = false;
        this.selectedSaltoFilter = { name: "activity.filter_all", key: "all" };
        this.saltoFilter = '';
      }
    },
    filterStatus(status){
      if(!status){
        this.selectedFilterValue = '';
        this.activityFilterBoxDisable = false;
        this.selectedFilter = { name: "activity.filter_all", key: "all" };
        this.selectedRange.index = 0;
        this.selectedRange.name = "activity.range_custom";
        /* this.selectedRange = {
          value: [
            this.$moment().subtract(7, "days").toDate(),
            this.$moment().toDate(),
          ],
          name: "activity.range_custom",
          changeable: true,
          index: 0,
        }; */
        this.filterVal = '';
      }
    },
    locationName(id) {
          let locationSelected = id;
          let name = "";
            this.getAllLocations.filter(
                      (item) => item.Location.Id == locationSelected
                  )
                  .map((item) => {
                    name = item.Location.Name;
                  });
          return name;
    },
    userFName(FirstName){
      if(FirstName === null || FirstName === "null" || FirstName === ""){
        FirstName = '';
      }
      return FirstName;
    },
    userLName(LastName){
      if(LastName === null  || LastName === "null" || LastName === ""){
        LastName = '';
      }
      return LastName;
    },
    removeLocationUser() {
      this.$session.set("userList", JSON.stringify(this.userList))
      this.showModal({ user: this.userList }, M_ADMIN_LOCATION_MULTIPLE_USER_DELETE);
      // this.showModal({ user: this.getAllUsers }, M_ADMIN_LOCATION_USER_DELETE);
    },
    deleteUser(user){
      /* if(user.Role == 'CompanyOwner'){
        console.log("role:"+user.UserId)
      } */
      this.showModal({ user: user }, M_ADMIN_USER_ACCESS_DELETE);
    },
    createSaltoInstallation(){
      document.getElementById("mySelect15").style.display = "none";
      document.getElementById("mySelect16").style.display = "none";
      this.showModal(null, M_SALTO_FORM);
    },
    createDevice() {
      document.getElementById("mySelect8").style.display = "none";
      document.getElementById("mySelect9").style.display = "none";
           this.$store.commit(SET_MODAL_DATA, {
                data: null,
                type: M_DEVICE_ADMIN_FORM,
            });
            this.$bvModal.show(M_DEVICE_ADMIN_FORM);
    },
    deviceQR(device) {
            this.showModal(
                { device: device.Device },
                M_DEVICE_QR
            );
        },
    createKey() {
      document.getElementById("mySelect10").style.display = "none";
      document.getElementById("mySelect11").style.display = "none";
      this.showModal(null, M_KEY_FORM_ADMIN);
    },
    createInvite() {
      document.getElementById("mySelect13").style.display = "none";
      document.getElementById("mySelect14").style.display = "none";
      document.getElementById("staffCompany").style.display = "none";
      if(this.companySelectedStaff == null){
        this.showModal(null, M_COMPANY_INVITE_ADMIN_FORM);
      }else{
        this.showModal(
          {
            invite: {
              CompanyId: this.companySelectedStaff,
              LocationId: null,
              Name: "",
              PhoneNumber: "",
              RoleType: null,
            }
          },
          M_COMPANY_INVITE_ADMIN_FORM
        );
      }
    },
    changeRole(user) {
      this.showModal({ user: user.User }, M_COMPANY_CHANGE_ROLE_ADMIN_FORM);
    },
    initCompany(){
      let mainCompanyParams = {
        page: 0,
        limit: this.perPageCompany,
        EventTypes:  this.$store.dispatch(ADMIN_COMPANIES_GET),
      };
      return mainCompanyParams;
    },
     initLocation(){
      let mainLocationParams = {
        page: 0,
        limit: this.perPageLocation,
        EventTypes:  this.$store.dispatch(ADMIN_LOCATIONS_GET),
      };
      return mainLocationParams;
    },
    initUser(){
      let mainUserParams = {
        page: 0,
        limit: this.perPageUser,
        EventTypes:  this.$store.dispatch(ADMIN_USERS_GET),
      };
      if (this.selectedUsersFilterValue) {
        switch (this.selectedUsersFilterValue.key) {
          case "LocationId": {
            if(this.selectedUsersFilterValue === '0'){
              mainUserParams.LocationId = '';
            }else{
              mainUserParams.LocationId = this.selectedUsersFilterValue;
            }
            break;
          }
         case "UserId": {
            if(this.selectedUsersFilterValue === '0'){
              mainUserParams.UserId = '';
            }else{
              mainUserParams.UserId = this.selectedUsersFilterValue;
            }
            break;
          }
          default:
            break;
        }
      }
      return mainUserParams;
    },
    setUsersFilter(usersFilter) {
      this.selectedUsersFilter = usersFilter;
      this.selectedUsersFilterValue = '';

      if (this.selectedUsersFilter.key == "all") {
        this.usersFilterBoxDisable = false
        this.initUser();
      }else{
        /* if(this.selectedUsersFilter.key == "LocationId"){
          this.checkBoxFlag = true
        } */
        this.usersFilterBoxDisable = true
      }
    },
    applyUserFilter(item) {
      this.$nextTick(() => {
        this.initUser();
      });
    },
    initKey(){
      let mainKeyParams = {
        page: 0,
        limit: this.perPageKey,
        EventTypes:  this.$store.dispatch(ADMIN_KEYS_GET),
      };
      if (this.selectedKeyFilterValue) {
        switch (this.selectedKeyFilterValue.key) {
          case "CompanyId": {
            if(this.selectedKeyFilterValue === '0'){
              mainKeyParams.CompanyId = '';
            }else{
              mainKeyParams.CompanyId = this.selectedKeyFilterValue;
            }
            break;
          }
          case "LocationId": {
            if(this.selectedKeyFilterValue === '0'){
              mainKeyParams.LocationId = '';
            }else{
              mainKeyParams.LocationId = this.selectedKeyFilterValue;
            }
            break;
          }
          case "DeviceId": {
            if(this.selectedKeyFilterValue === '0'){
              mainKeyParams.DeviceId = '';
            }else{
              mainKeyParams.DeviceId = this.selectedKeyFilterValue;
            }
            break;
          }
          case "KeyId": {
            if(this.selectedKeyFilterValue === '0'){
              mainKeyParams.Id = '';
            }else{
              mainKeyParams.Id = this.selectedKeyFilterValue;
            }
            break;
          }
          default:
            break;
        }
      }
      return mainKeyParams;
    },
    setKeyFilter(keyFilter) {
      this.selectedKeyFilter = keyFilter;
      this.selectedKeyFilterValue = '';
      if (this.selectedKeyFilter.key == "all") {
        this.keyFilterBoxDisable = false
        this.initKey();
      }else{
        this.keyFilterBoxDisable = true
      }
    },
    applyKeyFilter(item) {
      this.$nextTick(() => {
        this.initKey();
      });
    },
    initStaff(){
      let mainStaffParams = {
        page: 0,
        limit: this.perPageStaff,
        EventTypes:  this.$store.dispatch(ADMIN_STAFF_GET),
      };
      return mainStaffParams;
    },
    initSaltoInstallation(){
      let mainSaltoInstallationParams = {
        page: 0,
        limit: this.perPageSalto,
        EventTypes:  this.$store.dispatch(SALTO_INSTALLATION_GET),
      };
      if (this.selectedSaltoFilterValue) {
        switch (this.selectedSaltoFilterValue.key) {
          case "CompanyId": {
            if(this.selectedSaltoFilterValue === '0'){
              mainSaltoInstallationParams.CompanyId = '';
            }else{
              mainSaltoInstallationParams.CompanyId = this.selectedSaltoFilterValue;
            }
            break;
          }
          case "LocationId": {
            if(this.selectedSaltoFilterValue === '0'){
              mainSaltoInstallationParams.LocationId = '';
            }else{
              mainSaltoInstallationParams.LocationId = this.selectedSaltoFilterValue;
            }
            break;
          }
          case "Id": {
            if(this.selectedSaltoFilterValue === '0'){
              mainSaltoInstallationParams.Id = '';
            }else{
              mainSaltoInstallationParams.Id = this.selectedSaltoFilterValue;
            }
            break;
          }
          case "SaltoInstallationId": {
            if(this.selectedSaltoFilterValue === '0'){
              mainSaltoInstallationParams.SaltoInstallationId = '';
            }else{
              mainSaltoInstallationParams.SaltoInstallationId = this.selectedSaltoFilterValue;
            }
            break;
          }
          default:
            break;
        }
      }
      return mainSaltoInstallationParams;
    },
    setSaltoFilter(saltoFilter) {
      this.selectedSaltoFilter = saltoFilter;
      this.selectedSaltoFilterValue = '';
      if (this.selectedSaltoFilter.key == "all") {
        this.saltoFilterBoxDisable = false;
        this.initSaltoInstallation();
      }else{
        this.saltoFilterBoxDisable = true;
      }
    },
    applySaltoFilter(item) {
      this.$nextTick(() => {
        this.initSaltoInstallation();
      });
    },
    initDevice(){
      let mainDeviceParams = {
        page: 0,
        limit: this.perPageDevice,
        EventTypes:  this.$store.dispatch(ADMIN_DEVICES_GET),
      };
      if (this.selectedDeviceFilterValue) {
        switch (this.selectedDeviceFilter.key) {
          case "CompanyId": {
            if(this.selectedDeviceFilterValue === '0'){
              mainDeviceParams.CompanyId = '';
            }else{
              mainDeviceParams.CompanyId = this.selectedDeviceFilterValue;
            }
            break;
          }
          case "LocationId": {
            if(this.selectedDeviceFilterValue === '0'){
              mainDeviceParams.LocationId = '';
            }else{
              mainDeviceParams.LocationId = this.selectedDeviceFilterValue;
            }
            break;
          }
          case "DeviceId": {
            if(this.selectedDeviceFilterValue === '0'){
              mainDeviceParams.Id = '';
            }else{
              mainDeviceParams.Id = this.selectedDeviceFilterValue;
            }
            break;
          }
          case "KeyId": {
            if(this.selectedDeviceFilterValue === '0'){
              mainDeviceParams.KeyId = '';
            }else{
              mainDeviceParams.KeyId = this.selectedDeviceFilterValue;
            }
            break;
          }
          default:
            break;
        }
      }
      return mainDeviceParams;
    },
    setDeviceFilter(deviceFilter) {
      this.selectedDeviceFilter = deviceFilter;
      this.selectedDeviceFilterValue = '';
      if (this.selectedDeviceFilter.key == "all") {
        this.deviceFilterBoxDisable = false;
        this.initDevice();
      }else{
        this.deviceFilterBoxDisable = true;
      }
    },
    applyDeviceFilter(item) {
      this.$nextTick(() => {
        this.initDevice();
      });
    },

    initActivityRange(range) {
      this.selectedRange.value = range;
      this.initActivity();
    },
    initActivity() {
      this.activity = [];
      let startDate
      let endDate
      let activityArray = []
      if(this.yearRange){
        for(let i=0; i<=12; i++){
          // console.log("i:"+i)
          if(i == 0){
            startDate = new Date(this.selectedRange.value[0]).toISOString()
          }
          /* if(i == 4){
            endDate = this.$moment(startDate).add(5, "days").toDate().toISOString()
          }else{ */
            endDate = this.$moment(startDate).add(30, "days").toDate().toISOString()
          // }
            // .toISOString()

          // if(i==0){
            this.activity = []
            /* console.log("this.activity 1:"+this.activity.length)
            console.log("startDate:"+startDate)
            console.log("endDate:"+endDate) */
            this.loadActivity(this.buildActivityParams(startDate, endDate), ACTIVITY_LOG_ADMIN)
            // console.log("this.activity 2:"+this.activity.length)
            activityArray = activityArray.concat(this.activity)

            startDate = endDate

           // const timeout = setTimeout(() => {

           // }, 60000)
            // this.loadActivity(this.buildActivityParams(startDate, endDate), ACTIVITY_LOG_ADMIN);
            // console.log("this.activity 2:"+this.activity.length)
            // activityArray = activityArray.concat(this.activity)
          /* }else{
            this.activity = []
            console.log("startDate:"+startDate)
            console.log("endDate:"+endDate)
            console.log("this.activity 2:"+this.activity.length)
            window.setInterval(() => {
              this.loadActivity(this.buildActivityParams(startDate, endDate), ACTIVITY_LOG_ADMIN)
            }, 600000)
            // this.loadActivity(this.buildActivityParams(startDate, endDate), ACTIVITY_LOG_ADMIN);
            /* setTimeout(function () {
                this.loadActivity(this.buildActivityParams(startDate, endDate), ACTIVITY_LOG_ADMIN) }.bind(this),
                600000) */
            /* setTimeout(
                  this.loadActivity(this.buildActivityParams(startDate, endDate), ACTIVITY_LOG_ADMIN),
                  600000
                ); */
          //  console.log("this.activity 3:"+this.activity.length)
           /*  if((this.activity.length > 0) && (i > 0)){
              i++
              console.log("this.activity 2:"+this.activity.length)
              activityArray = activityArray.concat(this.activity)
            }else if(this.activity.length > 0){
              i++
              activityArray = activityArray.concat(this.activity)
            } */
          // }

          /* for(let j=0;j<this.activity.length;j++){
            console.log("activityArray:"+this.activity[j].Id)
          } */
         // activityArray = activityArray.concat(this.activity)
          // console.log("activityArray:"+activityArray)

        }
        this.activity = activityArray
        // console.log("this.activity:"+this.activity.length)
        // console.log("activityArray:"+activityArray.length)
    }else{
      startDate = new Date(this.selectedRange.value[0]).toISOString()
      endDate = this.$moment(this.selectedRange.value[1]).endOf("day").toISOString()
      this.loadActivity(this.buildActivityParams(startDate, endDate), ACTIVITY_LOG_ADMIN);
    }
    },
    buildActivityParams(startDate, endDate) {
    // initActivity() {
      /* let mainParams;
      let startDate
      let endDate
      let activityArray = []
     if(this.yearRange){
        for(let i=0; i<=4; i++){
        console.log("i:"+i)
        if(i == 0){
          startDate = new Date(this.selectedRange.value[0]).toISOString()
        }
        if(i == 4){
          endDate = this.$moment(startDate).add(5, "days").toDate().toISOString()
        }else{
          endDate = this.$moment(startDate).add(90, "days").toDate().toISOString()
        }
           // .toISOString()

        console.log("startDate:"+startDate)
        console.log("endDate:"+endDate)

          mainParams = {
            page: 0,
            limit: this.perAdminPage,
            StartDate: startDate,
            EndDate: endDate,
          };

          if (this.selectedFilterValue) {
            switch (this.selectedFilter.key) {
              case "KeyId": {
                if(this.selectedFilterValue === '0'){
                  mainParams.KeyId = '';
                }else{
                  mainParams.KeyId = this.selectedFilterValue;
                }
                break;
              }
              case "DeviceId": {
                if(this.selectedFilterValue === '0'){
                  mainParams.DeviceId = '';
                }else{
                  mainParams.DeviceId = this.selectedFilterValue;
                }
                break;
              }
              case "UserId": {
                if(this.selectedFilterValue === '0'){
                  mainParams.UserId = '';
                }else{
                  mainParams.UserId = this.selectedFilterValue;
                }
                break;
              }
              case "CompanyId": {
                if(this.selectedFilterValue === '0'){
                  mainParams.CompanyId = '';
                }else{
                  mainParams.CompanyId = this.selectedFilterValue;
                }
                break;
              }
              case "LocationId": {
                if(this.selectedFilterValue === '0'){
                  mainParams.LocationId = '';
                }else{
                  mainParams.LocationId = this.selectedFilterValue;
                }
                break;
              }
              case "PhoneNumber": {
                if(this.selectedFilterValue == '0'){
                  mainParams.UserId = '';
                }else{
                  mainParams.UserId = this.selectedFilterValue;
                }
                break;
              }
              case "Manager": {
                if(this.selectedFilterValue == '0'){
                  mainParams.User.Id = '';
                }else{
                  mainParams.User.Id = this.selectedFilterValue;
                }
                break;
              }
              default:
                break;
            }
          }
          startDate = endDate
          // activityArray.push(mainParams)
          // console.log("activityArray 1:"+activityArray.length)
        }
        // console.log("activityArray 2:"+activityArray.length)
        // mainParams = activityArray
      }else{ */
        let mainParams = {
          page: 0,
          limit: this.perAdminPage,
          StartDate: startDate,
          EndDate: endDate,
          // EventTypes: this.getActivityTypes(),
          // EventTypes: this.$store.dispatch(ADMIN_ACTIVITY_GET),
          // StartDate: this.selectedRange.value[0],
          /* EndDate: this.$moment(this.selectedRange.value[1])
            .endOf("day")
            .toISOString(), */
        };
       /* console.log("trial start:"+startDate)
        console.log("trial end:"+endDate) */
        if (this.selectedFilterValue) {
          switch (this.selectedFilter.key) {
            case "KeyId": {
              if(this.selectedFilterValue === '0'){
                mainParams.KeyId = '';
              }else{
                mainParams.KeyId = this.selectedFilterValue;
              }
              break;
            }
            case "DeviceId": {
              if(this.selectedFilterValue === '0'){
                mainParams.DeviceId = '';
              }else{
                mainParams.DeviceId = this.selectedFilterValue;
              }
              break;
            }
            case "UserId": {
              if(this.selectedFilterValue === '0'){
                mainParams.UserId = '';
              }else{
                mainParams.UserId = this.selectedFilterValue;
              }
              break;
            }
            case "CompanyId": {
              if(this.selectedFilterValue === '0'){
                mainParams.CompanyId = '';
              }else{
                mainParams.CompanyId = this.selectedFilterValue;
              }
              break;
            }
            case "LocationId": {
              if(this.selectedFilterValue === '0'){
                mainParams.LocationId = '';
              }else{
                mainParams.LocationId = this.selectedFilterValue;
              }
              break;
            }
            case "PhoneNumber": {
              if(this.selectedFilterValue == '0'){
                mainParams.UserId = '';
              }else{
                mainParams.UserId = this.selectedFilterValue;
              }
              break;
            }
            case "Manager": {
              if(this.selectedFilterValue == '0'){
                mainParams.User.Id = '';
              }else{
                mainParams.User.Id = this.selectedFilterValue;
              }
              break;
            }
            default:
              break;
          }
        }

      // }
      return mainParams;
    },
    initFilters(){
      this.$store.dispatch(ADMIN_DEVICES_GET);
      this.$store.dispatch(ADMIN_USERS_GET);
      this.$store.dispatch(ADMIN_KEYS_GET);
      this.$store.dispatch(ADMIN_COMPANIES_GET);
      this.$store.dispatch(ADMIN_LOCATIONS_GET);
    },
    getActivityTypes() {
      return activityAllTypes();
      /* switch (this.mode) {
        case 0:
          return activityAllTypes();
        case 1:
          return activityEventTypes();
        case 2:
          return activityAdminTypes();
      } */
    },
    setRange(range) {
      this.selectedRange = range;
      if(range.name == "activity.range_7_days"){
        this.yearRange = false;
        this.flatpickrRange = [
          this.$moment().subtract(7, "days").toDate(),
          this.$moment().toDate(),
        ]
      }else if(range.name == "activity.range_30_days"){
        this.yearRange = false;
        this.flatpickrRange = [
          this.$moment().subtract(30, "days").toDate(),
          this.$moment().toDate(),
        ]
      }else if(range.name == "activity.range_90_days"){
        this.yearRange = false;
        this.flatpickrRange = [
          this.$moment().subtract(90, "days").toDate(),
          this.$moment().toDate(),
        ]
      }else if(range.name == "activity.range_year"){
        this.yearRange = true;
        this.flatpickrRange = [
          this.$moment().subtract(365, "days").toDate(),
          this.$moment().toDate(),
        ]
      }
      this.initActivity();
    },
    setFilter(filterVal) {
      // console.log("7:"+filterVal)
      this.selectedFilter = filterVal;
      this.selectedFilterValue = '';
      if (this.selectedFilter.key == "all") {
        this.activityFilterBoxDisable = false;
        this.initActivity();
      }else{
        this.activityFilterBoxDisable = true;
      }
      // console.log("8:"+this.selectedFilter.key)
    },
    applyFilter(item) {
      this.$nextTick(() => {
        this.initActivity();
      });
    },
    exportActivity() {
      document.getElementById("mySelect18").style.display = "none";
      this.exporting = true;
      // let params = this.initActivity();
      // params.IgnorePagination = true;
      let params = this.buildActivityParams();
      params.IgnorePagination = true;
      AggregatedController.getActivityLog(params, ACTIVITY_LOG_ADMIN).then(
        (response) => {
           let mapped = response.data.map((item) => {
            /* if(item.User.Name.includes(null)){
              item.User.Name = item.User.Name.replace(null, "");
            }
            if(item.User.LastName === null){
              item.User.LastName = '';
            } */
            return {
              date: this.$moment
                .utc(item.Time)
                .local()
                .format("YYYY-MM-DD HH:mm"),
              message: this.translateMessage(item),
            };
          });
          let file = this.$papa.unparse(mapped);
          this.$papa.download(file, "activity" + this.$moment().toDate());
          this.exporting = false;
        }
      );
    },translateMessage(activityItem) {

      /* if(activityItem.Device == null) {return}
      if(activityItem.Key == null) {return}
      if(activityItem.Location == null) {return}
      if(activityItem.Company == null) {return}
      if(activityItem.User == null) {return}
      if(activityItem.User.FirstName == "null" || activityItem.User.FirstName == null){
        activityItem.User.FirstName = '';
      }
      if(activityItem.User.LastName == "null" || activityItem.User.LastName == null){
        activityItem.User.LastName = '';
      }
       if(activityItem.Location.Name == "null" || activityItem.Location.Name == null){
        activityItem.Location.Name = '';
      }
      if(activityItem.Device.Name == "null" || activityItem.Device.Name == null){
        activityItem.Device.Name = '';
      }
      if(activityItem.Key.Name == "null" || activityItem.Key.Name == null){
        activityItem.Key.Name = '';
      }
      if(activityItem.Company.Name == "null" || activityItem.Company.Name == null){
        activityItem.Company.Name = '';
      } */

      let description = JSON.parse(activityItem.Description);
      switch (activityItem.Type) {
        case ACTIVITY_TYPE_KEY_CREATED:
        if(activityItem.Key != null && activityItem.Location != null && activityItem.Company != null){
          return this.$t("activity.key_created", {
            userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
            keyName: activityItem.Key.Name,
            locationName: activityItem.Location.Name,
            companyName: activityItem.Company.Name,
          });
        }
        case ACTIVITY_TYPE_KEY_UPDATED:
        if(activityItem.Key != null && activityItem.Location != null && activityItem.Company != null){
          return this.$t("activity.key_updated", {
            userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
            keyName: activityItem.Key.Name,
            locationName: activityItem.Location.Name,
            companyName: activityItem.Company.Name,
          });
        }
        case ACTIVITY_TYPE_KEY_DELETED:
          return this.$t("activity.key_deleted", {
            userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
            // keyName: activityItem.Key,
          });
        case ACTIVITY_TYPE_DEVICE_UPDATED:
          if(activityItem.Device != null && activityItem.Location != null && activityItem.Company != null){
            return this.$t("activity.door_updated", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              doorName: activityItem.Device.Name,
              locationName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_DEVICE_DELETED:
          return this.$t("activity.door_deleted", {
            userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
            // doorName: activityItem.Device,
          });
        case ACTIVITY_TYPE_DEVICE_CREATED:
          if(activityItem.Device != null && activityItem.Location != null && activityItem.Company != null){
            return this.$t("activity.door_created", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              doorName: activityItem.Device.Name,
              locationName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_UPDATED:
          if(activityItem.Device != null){
            return this.$t("activity.salto_door_updated", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              doorName: activityItem.Device.Name,
            });
          }
        case ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_DELETED:
          return this.$t("activity.salto_door_deleted", {
            userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
            // doorName: activityItem.Device,
          });
        case ACTIVITY_TYPE_SALTO_ONLINE_DEVICE_CREATED:
          if(activityItem.Device != null){
            return this.$t("activity.salto_door_created", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              doorName: activityItem.Device.Name,
            });
          }
        case ACTIVITY_TYPE_SALTO_INSTALLATION_CREATED:
          if(activityItem.Location != null && activityItem.Company != null && activityItem.Installation != null){
            return this.$t("notify.salto_installation_created_msg", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              saltoInstallationName: activityItem.Installation.Installation.Name,
              locationName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_SALTO_INSTALLATION_UPDATED:
          if(activityItem.Location != null && activityItem.Company != null && activityItem.Installation != null){
            return this.$t("notify.salto_installation_updated_msg", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              saltoInstallationName: activityItem.Installation.Installation.Name,
              locationName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_SALTO_INSTALLATION_DELETED:
            return this.$t("notify.salto_installation_deleted_msg", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              // saltoInstallationName: activityItem.Installation.Installation.Name,
              // locationName: activityItem.Location.Name,
              // companyName: activityItem.Company.Name,
            });
        case ACTIVITY_TYPE_LOCATION_CREATED:
          if(activityItem.Location != null && activityItem.Company != null){
            return this.$t("activity.location_created", {
              userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              locationName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_LOCATION_UPDATED:
          if(activityItem.Location != null && activityItem.Company != null){
            return this.$t("activity.location_updated", {
                userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                locationName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_LOCATION_DELETED:
          if(activityItem.Company != null){
            return this.$t("activity.location_deleted", {
                userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                // locationName: activityItem.Location,
                companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_INVITE_CREATED:
          // console.log("id: "+activityItem.Id)
          // console.log("description.RoleName:"+description.RoleName)
          if(description != null){
            switch (description.RoleName) {
              case ROLE_KEY_PERMANENT:
                if(activityItem.Key != null &&activityItem.Location != null && activityItem.Company != null){
                  return this.$t("activity.key_invite_sent", {
                    senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    // recepientName: description.RecipientName,
                    targetName: activityItem.Key.Name,
                    locationName: activityItem.Location.Name,
                    companyName: activityItem.Company.Name,
                    phoneNumber: description.RecipientPhoneNumber,
                  });
                }
              case ROLE_KEY_TEMPORARY:
                if(activityItem.Key != null && activityItem.Location != null && activityItem.Company != null){
                  return this.$t("activity.key_invite_sent", {
                    senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    // recepientName: description.RecipientName,
                    targetName: activityItem.Key.Name,
                    locationName: activityItem.Location.Name,
                    companyName: activityItem.Company.Name,
                    phoneNumber: description.RecipientPhoneNumber,
                  });
                }
              case ROLE_COMPANY_MANAGER:
                if(activityItem.Company != null){
                  return this.$t("activity.cmanager_invite_sent", {
                    senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    recepientName: description.RecipientName,
                    targetName: activityItem.Company.Name,
                  });
                }
              case ROLE_LOCATION_MANAGER:
                if(activityItem.Location != null && activityItem.Company != null){
                  return this.$t("activity.lmanager_invite_sent", {
                    senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    recepientName: description.RecipientName,
                    targetName: activityItem.Location.Name,
                    companyName: activityItem.Company.Name,
                  });
                }
              case ROLE_DEVICE_INSTALLER:
                if(activityItem.Location != null && activityItem.Company != null){
                  return this.$t("activity.installer_invite_sent", {
                    senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    recepientName: description.RecipientName,
                    targetName: activityItem.Location.Name,
                    companyName: activityItem.Company.Name,
                  });
                }
              case ROLE_KEY_MANAGER:
                if(activityItem.Location != null && activityItem.Company != null){
                  return this.$t("activity.kmanager_invite_sent", {
                    senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    recepientName: description.RecipientName,
                    targetName: activityItem.Location.Name,
                    companyName: activityItem.Company.Name,
                  });
                }
              case ROLE_COMPANY_OWNER:
                if(activityItem.Company != null){
                  return this.$t("activity.cowner_invite_sent", {
                    senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    recepientName: description.RecipientName,
                    targetName: activityItem.Company.Name,
                  });
                }
            }
          }
          return activityItem.Name;
        case ACTIVITY_TYPE_ASSIGNED_ROLE_CO:
          if(activityItem.Company != null){
            return this.$t("activity.cowner_assigned", {
              senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              recepientName: description.RecipientName,
              targetName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_ASSIGNED_ROLE_CM:
          if(activityItem.Company != null){
            return this.$t("activity.cmanager_assigned", {
              senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              recepientName: description.RecipientName,
              targetName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_ASSIGNED_ROLE_LM:
          if(activityItem.Location != null && activityItem.Company != null){
            return this.$t("activity.lmanager_assigned", {
              senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              recepientName: description.RecipientName,
              targetName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_ASSIGNED_ROLE_KM:
          if(activityItem.Location != null && activityItem.Company != null){
            return this.$t("activity.kmanager_assigned", {
              senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              recepientName: description.RecipientName,
              targetName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_ASSIGNED_ROLE_INSTALLER:
          if(activityItem.Location != null && activityItem.Company != null){
            return this.$t("activity.installer_assigned", {
              senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
              recepientName: description.RecipientName,
              targetName: activityItem.Location.Name,
              companyName: activityItem.Company.Name,
            });
          }
        case ACTIVITY_TYPE_INVITE_ACCEPTED:
          if(description.RoleName != null){
            switch (description.RoleName) {
              case ROLE_KEY_PERMANENT:
                if(activityItem.Key != null){
                  return this.$t("activity.key_invite_accepted", {
                    userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    targetName: activityItem.Key.Name,
                  });
                }
              case ROLE_KEY_TEMPORARY:
                if(activityItem.Key != null){
                  return this.$t("activity.key_invite_accepted", {
                    userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    targetName: activityItem.Key.Name,
                  });
                }
              case ROLE_COMPANY_MANAGER:
                if(activityItem.Company != null){
                  return this.$t("activity.cmanager_invite_accepted", {
                    userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    targetName: activityItem.Company.Name,
                  });
                }
              case ROLE_LOCATION_MANAGER:
                if(activityItem.Location != null){
                  return this.$t("activity.lmanager_invite_accepted", {
                    userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    targetName: activityItem.Location.Name,
                  });
                }
              case ROLE_DEVICE_INSTALLER:
                if(activityItem.Location != null){
                  return this.$t("activity.installer_invite_accepted", {
                    userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    targetName: activityItem.Location.Name,
                  });
                }
              case ROLE_COMPANY_OWNER:
                if(activityItem.User != null || activityItem.Company != null){
                  return this.$t("activity.cowner_invite_accepted", {
                    userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                    targetName: activityItem.Company.Name,
                  });
                }
            }
          }
          return activityItem.Name;
          case ACTIVITY_TYPE_COMPANY_CREATED:
            if(activityItem.Company != null){
              return this.$t("activity.company_created", {
                  userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                  companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_COMPANY_UPDATED:
            if(activityItem.Company != null){
              return this.$t("activity.company_updated", {
                  userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                  companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_COMPANY_DELETED:
            if(activityItem.User != null){
              return this.$t("activity.company_deleted", {
                  userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                  // companyName: activityItem.Company,
              });
            }
          case ACTIVITY_TYPE_USER_REMOVED_FROM_KEY:
            if(activityItem.Key != null && activityItem.Company != null){
              return this.$t("activity.user_removed_from_key", {
                  userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                  phoneNumber: description.UserPhoneNumber,
                  keyName: activityItem.Key.Name,
                  companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_USER_REMOVED_FROM_LOCATION:
            if(activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.user_removed_from_location", {
                  userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                  phoneNumber: description.UserPhoneNumber,
                  locationName: activityItem.Location.Name,
                  companyName: activityItem.Company.Name,
              });
            }
          /* case ACTIVITY_TYPE_REMOVED_STAFF:
            return this.$t("activity.user_removed_staff", {
                senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                // roleName: description.RoleName,
                targetName: activityItem.Company.Name,
              }); */
          case ACTIVITY_TYPE_REMOVED_ROLE_COMPANY_MANAGER:
            if(activityItem.Company != null){
              return this.$t("activity.user_role_removed_cm", {
                  senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                  roleName: description.RoleName,
                  targetName: activityItem.Company.Name,
                });
              }
          case ACTIVITY_TYPE_REMOVED_ROLE_LOCATION_MANAGER:
            if(activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.user_role_removed_lm", {
                  senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                  // roleName: description.RoleName,
                  targetName: activityItem.Location.Name,
                  companyName: activityItem.Company.Name,
                });
              }
          case ACTIVITY_TYPE_REMOVED_ROLE_KEY_MANAGER:
            if(activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.user_role_removed_km", {
                senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                // roleName: description.RoleName,
                targetName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_REMOVED_ROLE_INSTALLER:
            if(activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.user_role_removed_instl", {
                senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                // roleName: description.RoleName,
                targetName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
          // case ACTIVITY_TYPE_DEVICE_LOCKED:
          case ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_FAIL:
            if(activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.door_unlock_fail",{
                locationName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
          // case ACTIVITY_TYPE_DEVICE_UNLOCKED:
          case ACTIVITY_TYPE_DEVICE_UNLOCKED_DIALPAD_SUCCESS:
            if(activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.door_unlock_success",{
                locationName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
          // case ACTIVITY_TYPE_DEVICE_LOCKED_USER:
          case ACTIVITY_TYPE_DEVICE_LOCKED:
            if(activityItem.Device != null){
              return this.$t("activity.door_locked_user", {
                userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                doorName: activityItem.Device.Name,
              });
            }
          // case ACTIVITY_TYPE_DEVICE_UNLOCKED_USER:
          case ACTIVITY_TYPE_DEVICE_UNLOCKED:
            if(activityItem.Device != null && activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.door_unlocked_user", {
                userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                doorName: activityItem.Device.Name,
                locationName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_DEVICE_LOCK_FAIL:
            if(activityItem.Device != null){
              return this.$t("activity.door_locked_user_fail", {
                userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                doorName: activityItem.Device.Name,
              });
            }
          case ACTIVITY_TYPE_DEVICE_UNLOCK_FAIL:
            if(activityItem.Device != null && activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.door_unlocked_user_fail", {
                userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                doorName: activityItem.Device.Name,
                locationName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_TRANSFER_RIGHTS:
            if(activityItem.Company != null){
              return this.$t("activity.transfer_rights", {
                userName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                // phoneNumber: description.RecipientPhoneNumber,
                oldName: description.RecipientName,
                companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_CONNECT_DEVICES:
            if(activityItem.Device != null && activityItem.Key != null && activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.devices_connected", {
                senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                keyName: activityItem.Key.Name,
                doorName: activityItem.Device.Name,
                locationName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
          case ACTIVITY_TYPE_DISCONNECT_DEVICES:
            if(activityItem.Device != null && activityItem.Key != null && activityItem.Location != null && activityItem.Company != null){
              return this.$t("activity.devices_disconnected", {
                senderName: activityItem.User.FirstName +" "+ activityItem.User.LastName,
                keyName: activityItem.Key.Name,
                doorName: activityItem.Device.Name,
                locationName: activityItem.Location.Name,
                companyName: activityItem.Company.Name,
              });
            }
      }
    },

    validatePhone(phone) {
      this.validPhone = phone.isValid;
      this.fullPhone = phone;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.user[name];
      return $dirty && this.submitted ? !$error : null;
    },
    createLocation() {
      document.getElementById("mySelect3").style.display = "none";
      document.getElementById("mySelect4").style.display = "none";
      document.getElementById("locationCompany").style.display = "none";
      if(this.companySelected == null){
        this.showModal(null, M_LOCATION_FORM_ADMIN);
      }else{
        this.showModal(
          {
            location: {
              Name: "",
              Address: "",
              CompanyId: this.companySelected,
            }
          },
          M_LOCATION_FORM_ADMIN
        );
      }
    },
    inviteMember() {
      document.getElementById("mySelect5").style.display = "none";
      document.getElementById("mySelect6").style.display = "none";
      this.showModal(null, M_MEMBER_INVITE_ADMIN_FORM);
    },
    updateProfile() {
      this.submitted = true;
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      let payload = {
        ...this.user,
        ...{
          PhoneNumber: this.fullPhone.formattedNumber,
          Name: this.user.FirstName,
        },
      };
      this.$store
        .dispatch(USER_PATCH, payload)
        .then((response) => {
          this.$bvModal.hide(this.type);
        })
        .catch((error) => {});
    },
    onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 1200){
                this.isMobile = true
            }else{
                this.isMobile = false
            }
            return this.isMobile;
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }
            return this.isMobile;
        },
    createCompany() {
      document.getElementById("mySelect1").style.display = "none";
      document.getElementById("mySelect2").style.display = "none";
      this.showModal(null, M_COMPANY_ADMIN_FORM);
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  destroyed() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("onload", this.onLoad);
        this.isMobile = false;
    },
  components: {
    "zs-activity-timeline": Timeline,
    "zs-device-admin-modal": CreateDeviceAdminModal,
    "zs-device-delete-admin-modal": DeleteDeviceAdminModal,
    "zs-list-transition": ListTransition,
    "location-list-item-admin": LocationListItemAdmin,
    "door-card-item-admin": DoorCardItemAdmin,
    "door-large-card-item-admin": DoorLargeCardItemAdmin,
    "salto-list-item": SaltoListItem,
    "key-list-item-admin": KeyListItemAdmin,
    "user-company-item-admin": UserCompanyItemAdmin,
    "empty-list": EmptyList,
    "employee-list-item-admin": EmployeeListItemAdmin,
    "zs-delete-company-user-admin-modal": DeleteUserAdminModal,
    "zs-company-admin-modal": CreateCompanyAdminModal,
    "zs-company-delete-admin-modal": DeleteCompanyAdminModal,
    "zs-delete-location-user-admin-modal": DeleteLocationUserAdminModal,
    "zs-delete-location-multiple-user-admin-modal": DeleteLocationMultipleUserAdminModal,
    "zs-salto-installation-modal": CreateSaltoInstallationModal,
    "zs-salto-installation-delete-admin-modal": DeleteSaltoInstallationModal,
    "zs-location-admin-modal": CreateLocationAdminModal,
    "zs-location-delete-admin-modal": DeleteLocationAdminModal,
    "zs-key-admin-modal": CreateKeyAdminModal,
    "zs-key-delete-admin-modal": DeleteKeyAdminModal,
    "zs-cancel-invite-admin-modal": CancelInviteMemberAdminModal,
    "zs-delete-key-user-admin-modal": DeleteKeyUserAdmin,
    "zs-company-invite-admin-modal": InviteCompanyEmployeeAccordionAdminModal,
    "zs-change-location-role-admin-modal": ChangeLocationEmployeeRoleAdmin,
    "zs-change-owner-modal": ChangeOwnerModal,
    "zs-change-company-role-admin-modal": ChangeCompanyEmployeeRoleAdmin,
    "zs-sharekey-admin-modal": ShareKeyAdminModal,
    "zs-image-upload-modal": ImageUploadModal,
    "zs-add-door-modal": AddDoorModal,
    "zs-disconnect-door-modal": DisconnectDoorModal,
    "zs-device-qr-modal": DeviceQRModal,
    "zs-user-access-delete-modal": UserAccessDeleteModal,
    Select2,
    flatPickr,
  },
};
</script>
<style scoped>
.badge.bg-success-soft {
  color: #00d97e;
}
.b-border {
  border-bottom: 1px solid #edf2f9;
  padding-bottom: 0px;
}
.select2-dropdown .dropdown-menu .select-dropdown{
  left: -32px !important;
}
.select2-search__field {
    width: auto !important;
    padding: 0.125rem 2.5rem !important;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='16' opacity='0.5' class='bi bi-search' style='margin-left: 2px' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat left;
}
li.select2-search--inline {
    width: auto !important;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.list-group-item:last-child{
  overflow-y: auto !important;
}
</style>
